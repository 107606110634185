.section {
  min-width: 271.76px;
  max-width: 398px;
  padding: var(--CONTENT-TOP-BOTTOM-PADDING) var(--GUTTER);
  border-radius: 0.222rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.h2 {
  margin-bottom: 1rem;
  color: var(--PRIMARY-COLOR);
}

.msgContainer {
  max-width: 400px;
  min-width: 250px;
}

.msg {
  color: var(--SECONDARY-COLOR);
}

.return {
  text-align: left;
  font-size: 1.125rem;
  font-weight: lighter;
  margin: 1rem 0;
}

.errMsg {
  color: var(--ERROR-PRIMARY-COLOR);
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.resendContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.500rem;
  border-radius: 0.222rem;
  border: var(--ERROR-BORDER);
  padding: 0.5rem;
  background-color: var(--ERROR-BGCOLOR);

}

.checkInbox {
  color: var(--ERROR-PRIMARY-COLOR);
}

.resend {
  white-space: nowrap;
  color: var(--ERROR-PRIMARY-COLOR);
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.oauth {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 213px;
}