.section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  padding: 0.5rem;
  border-radius: 0.222rem;
  border: var(--ERROR-BORDER);
  background-color: var(--ERROR-BGCOLOR);
  color: var(--ERROR-PRIMARY-COLOR);
}

.flexCol {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.xmark {
  color: var(--ERROR-SECONDARY-COLOR);
  cursor: pointer;
}