.cefrContainer {
    grid-area: cefr;
    display: flex;
    flex-direction: row;
    /* opacity: 0; */
}

.currentLevel {
    height: 100%;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: var(--TARGET-FF);
    color: var(--TERTIARY-BGCOLOR);
    /* opacity: 0; */
}

.nextLevel {
    height: 100%;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: right;
    font-family: var(--TARGET-FF);
    color: var(--TERTIARY-BGCOLOR);
    /* opacity: 0; */
}

.cefrBarContainer {
    display: flex;
    flex: 1;
    align-items: center;
    height: 40px;
    width: auto;
    justify-content: flex-start;
}

.cefrBar {
    display: flex;
    height: 2px;
    background-color: var(--PRIMARY-BGCOLOR);
    flex: 1;
}

.progressBar {
    height: 2px;
    background-color: var(--TERTIARY-BGCOLOR);
    transition: width 500ms ease-out;
    display: flex;
    justify-content: flex-end;
}

.leftOfDecimal,
.decimal,
.rightOfDecimal {
    position: absolute;
    font-family: var(--TARGET-FF);
    color: var(--TERTIARY-BGCOLOR);
    font-weight: bold;
    font-size: .7rem;
    transition: opacity 500ms ease-out;
}

.leftOfDecimal,
.rightOfDecimal {
    display: flex;
    width: 4rem
}

.leftOfDecimal {
    justify-content: flex-end;
    transform: translate(-2px, -17px);
}

.decimal {
    transform: translate(1.5px, -15.5px);
}

.rightOfDecimal {
    transform: translate(65.5px, -17px);
}

.nextLevel.levelUp {
    animation: levelUp 10s ease-out;
}

.leftOfDecimal.levelUp {
    animation: levelUp 10s ease-out;
}

.decimal.levelUp {
    animation: levelUp 10s ease-out;
}

.rightOfDecimal.levelUp {
    animation: levelUp 10s ease-out;
}

@keyframes levelUp {
    0% {
        color: var(--TERTIARY-BGCOLOR)
    }

    5% {
        color: var(--NEW-STEM-COLOR)
    }

    95% {
        color: var(--NEW-STEM-COLOR)
    }

    100% {
        color: var(--TERTIARY-BGCOLOR)
    }
}

.levelUpBar {
    animation: levelUpBar 10s ease-out;
}

@keyframes levelUpBar {
    0% {
        background-color: var(--TERTIARY-BGCOLOR)
    }

    5% {
        background-color: var(--NEW-STEM-COLOR)
    }

    95% {
        background-color: var(--NEW-STEM-COLOR)
    }

    100% {
        background-color: var(--TERTIARY-BGCOLOR)
    }
}

.nextLevel.knownCountUp {
    animation: knownCountUp 500ms ease-out;
}

.leftOfDecimal.knownCountUp {
    animation: knownCountUp 500ms ease-out;
}

.decimal.knownCountUp {
    animation: knownCountUp 500ms ease-out;
}

.rightOfDecimal.knownCountUp {
    animation: knownCountUp 500ms ease-out;
}

@keyframes knownCountUp {
    0% {
        color: var(--TERTIARY-BGCOLOR)
    }

    50% {
        color: var(--NEW-STEM-COLOR)
    }

    100% {
        color: var(--TERTIARY-BGCOLOR)
    }
}

.currentLevel.knownCountDown {
    animation: knownCountDown 500ms ease-out;
}

.leftOfDecimal.knownCountDown {
    animation: knownCountDown 500ms ease-out;
}

.decimal.knownCountDown {
    animation: knownCountDown 500ms ease-out;
}

.rightOfDecimal.knownCountDown {
    animation: knownCountDown 500ms ease-out;
}

@keyframes knownCountDown {
    0% {
        color: var(--TERTIARY-BGCOLOR)
    }

    50% {
        color: var(--NEW-END1-COLOR)
    }

    100% {
        color: var(--TERTIARY-BGCOLOR)
    }
}

.knownCountUpBar {
    animation: knownCountUpBar 500ms ease-out;
}

@keyframes knownCountUpBar {
    0% {
        background-color: var(--TERTIARY-BGCOLOR)
    }

    50% {
        background-color: var(--NEW-STEM-COLOR)
    }

    100% {
        background-color: var(--TERTIARY-BGCOLOR)
    }
}

.knownCountDownBar {
    animation: knownCountDownBar 500ms ease-out;
}

@keyframes knownCountDownBar {
    0% {
        background-color: var(--TERTIARY-BGCOLOR)
    }

    50% {
        background-color: var(--NEW-END1-COLOR)
    }

    100% {
        background-color: var(--TERTIARY-BGCOLOR)
    }
}

.fadeIn {
    animation: fadeIn 500ms ease-out;
    opacity: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1
    }
}

.confettiOut {
    animation: confettiOut 10s ease-out;
}

@keyframes confettiOut {
    0% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.badge {
    z-index: 2000;
    height: 300px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 0%);
    opacity: 1;
}

.badgeFly {
    animation: badgeFly 10s ease-in-out;
}

@keyframes badgeFly {
    0% {
        top: -75%;
        opacity: 1;
    }

    45% {
        top: 30%;
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    90% {
        top: 20%;
    }

    100% {
        top: 20%;
        opacity: 0;
    }
}