.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* max-width: 315px; */
  z-index: 2;
  grid-area: toolbar;
}

.positionToolbar {
  position: absolute;
  bottom: 1px;
}


.toolbarContainer {
  pointer-events: auto;
  background: var(--SECONDARY-BGCOLOR);
  /* border: solid 1px var(--SELECTED-FRAGMENT-OUTLINE-COLOR); */
  outline: solid 1px var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  border-radius: 0.7rem;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px #000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 300px;
}

.button {
  all: unset;
  cursor: pointer;
  border-radius: .3em;
  margin: 5px 5px;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 28px;
  color: var(--SENTENCE-BTN-COLOR);
  transition: color 1s ease, background-color 500ms ease;
  position: relative;
  overflow: hidden;
}

.button.buttonOff {
  color: var(--PRIMARY-BGCOLOR);
  cursor: default;
}

.icon {
  width: 20px;
}

.check {
  font-size: 28px;
  width: 28px;
  color: var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  /* Define mask properties in the base class */
  mask: linear-gradient(90deg,
      rgba(255, 255, 255, .7) 0%,
      rgba(255, 255, 255, .7) 40%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, .7) 60%,
      rgba(255, 255, 255, .7) 100%);
  mask-size: 200% 100%;
  mask-type: alpha;
  transition: color 1s ease, mask-position 1s ease-out;
}

.checkUndo {
  color: var(--SENTENCE-BTN-COLOR);
  /* Adjust mask-position if needed */
  mask-position: 0% 0;
}

.glint {
  /* Keep the mask animation */
  mask-position: -20% 0;
  animation: glintAnimation 10s infinite;
}

.check.checkOff {
  color: var(--PRIMARY-BGCOLOR);
}

.eyeIcon,
.eyeSlashIcon {
  position: absolute;
  /* Stack the icons on top of each other */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Center the icons */
  transition: opacity 1s ease;
  /* Smooth opacity transition */
}

.eyeIcon {
  z-index: 1;
  width: 19px;
  height: 19px
    /* Below eyeSlashIcon */
}

.eyeSlashIcon {
  z-index: 2;
  width: 21px;
  height: 21px
    /* Above faEye */
}

.fadeIn {
  opacity: 1;
}

.fadeOut {
  opacity: 0;
}

@keyframes glintAnimation {
  0% {
    mask-position: 120% 0;
  }

  40% {
    mask-position: -20% 0;
    /* End of animation */
  }

  100% {
    mask-position: -20% 0;
    /* Pause (no change) */
  }
}

@media (pointer: fine) {
  .button:hover {
    background-color: var(--TERTIARY-BGCOLOR);
  }
}

@media (pointer: fine) {
  .button.off:hover {
    background-color: var(--SECONDARY-BGCOLOR);
  }
}

/* .playing {
  color: var(--TERTIARY-BGCOLOR);
} */

.outlineUp {
  animation: outlineUp 500ms ease-out;
}

@keyframes outlineUp {
  0% {
    outline: solid 1px var(--SELECTED-FRAGMENT-OUTLINE-COLOR);

  }

  50% {
    outline: solid 1px var(--NEW-STEM-COLOR);
  }

  100% {
    outline: solid 1px var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  }
}

.outlineDown {
  animation: outlineDown 500ms ease-out;
}

@keyframes outlineDown {
  0% {
    outline: solid 1px var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  }

  50% {
    outline: solid 1px var(--NEW-END1-COLOR);
  }

  100% {
    outline: solid 1px var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  }
}

.colorUp {
  animation: colorUp 500ms ease-out;
}

@keyframes colorUp {
  0% {
    color: var(--SECONDARY-COLOR);
  }

  50% {
    color: var(--NEW-STEM-COLOR);
  }
}

.colorDown {
  animation: colorDown 500ms ease-out forwards;
}

@keyframes colorDown {
  0% {
    color: var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  }

  50% {
    color: var(--NEW-END1-COLOR);
  }
}