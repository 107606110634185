.container {
  position: relative;
  width: 33px;
  height: 56px;
}

.logo {
  font-family: var(--TARGET-FF);
  font-size: 3.055rem;
  color: var(--SECONDARY-COLOR);
  letter-spacing: 2px;
  position: absolute;
  bottom: -1.5px;
  text-shadow: 1px 1px #000;
  white-space: nowrap;
}

.textOutline {
  color: inherit;
  position: relative;
  display: inline-block;
  line-height: 80%;
}

.textOutline::after {
  content: '';
  position: absolute;
  top: -4px;
  right: 0px;
  bottom: -1px;
  left: -2px;
  border: solid 1px var(--SECONDARY-COLOR);
  pointer-events: none;
  border-radius: 0.222rem;
}