.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.333rem;
  width: 100%;
}

.container:last-child {
  padding-bottom: 76px;
}

.itemRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0.667rem;
  padding: 0.222rem;

  border-radius: 0.222rem;
  background-color: var(--TERTIARY-BGCOLOR);
  border: 1px solid var(--PRIMARY-BGCOLOR);
  cursor: pointer;
}

.icon {
  color: var(--PRIMARY-COLOR);
  cursor: pointer;
}

.infoIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.111rem;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.item {
  cursor: pointer;
  color: var(--SECONDARY-COLOR);
  text-align: left;
  font-size: 1rem;
}

.item__selected {
  color: var(--PRIMARY-COLOR);
  text-decoration: underline;
  text-decoration-color: var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
}

.descriptionContainer {
  border-radius: 0.222rem;
  background-color: var(--TERTIARY-BGCOLOR);
  border: 1px solid var(--PRIMARY-BGCOLOR);
  padding: 0.222rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.444rem;
}

.valueSpan {
  font-style: italic;
}

.itemInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: var(--SECONDARY-COLOR);
}

.itemName {
  /* color: var(--SECONDARY-COLOR); */
  font-weight: 900;
  padding-bottom: 0.222rem;
  font-size: 1rem;
}

.itemDescription {
  /* background-color: var(--SECONDARY-COLOR); */
  color: var(--SECONDARY-COLOR);
  padding: 0.114rem 0.114rem 0.114rem 0.456rem;
  border-radius: 0.222rem;
  font-weight: 100;
  font-size: 1rem;
}

.valueDescription {
  color: var(--TERTIARY-BGCOLOR);
  background-color: var(--SECONDARY-COLOR);
  border-radius: 0.222rem;
  padding: 0.222rem;
  font-size: 1rem;
}