.container {
  min-height: var(--HERO-HEIGHT);
  max-width: var(--CONTENT-MAX-WIDTH);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  padding: var(--CONTENT-TOP-BOTTOM-PADDING) 0.555rem;
  background-color: var(--SECONDARY-BGCOLOR);
  /* gap: 1rem; */
}

.article {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  color: var(--SECONDARY-COLOR);
}

.header {
  /* font-size: 1.2rem; */
  font-weight: 900;
  align-items: center;
  color: var(--PRIMARY-COLOR);
  margin-bottom: 2rem;
}

.p {
  font-size: 1.2rem;
  line-height: 200%;
  text-align: justify;
}