.darkOverlay {
  height: 100%;
  flex-grow: 1;
  position: absolute;
  z-index: var(--GUIDE-OVERLAY-Z-INDEX);
  top: 0;
  bottom: 0;
  pointer-events: none;
  width: 100%;
  /* max-width: 1440px; */
  display: grid;
  /* place-content: end center; */
  background-color: hsla(0, 0%, 0%, 0.750);
  /* backdrop-filter: blur(1px); */
  pointer-events: auto;
}

.clickBlocker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--GUIDE-CLICKBLOCKER-Z-INDEX);
  pointer-events: auto;
}

.guideFocus {
  z-index: var(--GUIDE-FOCUS-Z-INDEX) !important;
  outline: 2px solid var(--SECONDARY-COLOR) !important;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR) !important;
  position: relative !important;
}

.guideFocusNoOutline {
  z-index: var(--GUIDE-FOCUS-Z-INDEX) !important;
  /* outline: 2px solid var(--SECONDARY-COLOR); */
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR) !important;
  position: relative !important;
}

.taskFocus {
  z-index: var(--GUIDE-CURRENT-TASK-Z-INDEX) !important;
  outline: 2px solid var(--SECONDARY-COLOR) !important;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR) !important;
  position: relative !important;
}

.taskFocus__noPosition {
  z-index: var(--GUIDE-CURRENT-TASK-Z-INDEX) !important;
  outline: 2px solid var(--SECONDARY-COLOR) !important;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR) !important;
  /* position: relative !important; */
}

.unclickable {
  pointer-events: none !important;
}

.taskFocus__unclickable {
  z-index: var(--GUIDE-CURRENT-TASK-Z-INDEX) !important;
  outline: 1px solid var(--SECONDARY-COLOR) !important;
  /* box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR); */
  position: relative !important;
  pointer-events: none !important;
}

.taskFocusNoBorder {
  z-index: var(--GUIDE-CURRENT-TASK-Z-INDEX) !important;
  /* outline: 1px solid var(--SECONDARY-COLOR); */
  /* box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR); */
  position: relative !important;
  /* pointer-events: none; */
}

.taskFocusNoBorder__unclickable {
  z-index: var(--GUIDE-CURRENT-TASK-Z-INDEX) !important;
  /* outline: 1px solid var(--SECONDARY-COLOR); */
  /* box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR); */
  position: relative !important;
  pointer-events: none !important;
}

.taskFocusOutlineOffset {
  z-index: var(--GUIDE-CURRENT-TASK-Z-INDEX) !important;
  outline: 1px solid var(--SECONDARY-COLOR) !important;
  outline-offset: 6px !important;
  position: relative !important;
}

.taskFocusOutlineOffset__unclickable {
  z-index: var(--GUIDE-CURRENT-TASK-Z-INDEX) !important;
  /* z-index: 999999999 !important; */
  outline: 1px solid var(--SECONDARY-COLOR) !important;
  outline-offset: 6px !important;
  position: relative !important;
  pointer-events: none !important;
}

.iPhoneSucks {
  position: relative !important;
  z-index: var(--GUIDE-CURRENT-TASK-Z-INDEX) !important;
}

.iPhoneSucks__positionOnly {
  position: relative !important;
  /* z-index: var(--GUIDE-CURRENT-TASK-Z-INDEX) !important; */
}

.taskFocusBorder {
  z-index: var(--GUIDE-CURRENT-TASK-Z-INDEX) !important;
  border: 2px solid var(--SECONDARY-COLOR) !important;
  /* box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR); */
  position: relative !important;
  /* pointer-events: none; */
  border-radius: 0.222rem !important;
}

.taskFocusBorder__unclickable {
  z-index: var(--GUIDE-CURRENT-TASK-Z-INDEX) !important;
  border: 2px solid var(--SECONDARY-COLOR) !important;
  /* box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR); */
  position: relative !important;
  /* pointer-events: none; */
  border-radius: 0.222rem !important;
}

.unfocus__unclickable {
  /* z-index: var(--GUIDE-CLICKBLOCKER-Z-INDEX) !important; */
  color: var(--SENTENCE-DISABLED-BTN-COLOR) !important;
  /* background-color: var(--TOOLBAR-BGCOLOR); */
  pointer-events: none !important;
}

.focusOverlay {
  z-index: var(--GUIDE-FOCUS-Z-INDEX) !important;
}

.selectedFragment {
  outline: 1px solid var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  border-radius: 0.090rem;
  padding: 0.050rem;
}

.targetBg {
  display: inline-block;
  border-radius: 0.222rem;
  padding: 0.500rem;
  background-color: var(--SENTENCE-TARGET-BGCOLOR);
}

.selectedWord {
  background-color: var(--SELECTED-WORD-BGCOLOR);
  padding: 0.050rem;
}

.alignRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.spaceBetween {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.emphasize {
  color: var(--PRIMARY-COLOR);
  font-style: italic;
  font-weight: 900;
}

.bold {
  font-weight: 900;
}

.primaryBold {
  color: var(--PRIMARY-COLOR);
  font-weight: 900;
}

.outlined {
  outline: 1px solid var(--PRIMARY-COLOR);
}

.noScroll {
  overflow-y: hidden;
}

.nextContainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  color: var(--PRIMARY-COLOR);
  /* font-style: italic; */
  font-weight: 900;
  cursor: pointer;
}

.prevContainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  color: var(--PRIMARY-COLOR);
  /* font-style: italic; */
  font-weight: 900;
  cursor: pointer;
}

.guideDropdown0 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: -10px;
  transform: translateY(100%);
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown1 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: -10px;
  transform: translateY(100%);
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown11 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  top: 0;
  /* transform: translateY(27%); */
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown2 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: -10px;
  transform: translateY(100%);
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown3 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: -10px;
  transform: translateY(100%);
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
  max-height: 240px;
  overflow-y: auto;
}

.guideDropdown4 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: -10px;
  transform: translateY(100%);
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown41 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  top: 0;
  /* transform: translateY(50%); */
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown5 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: -10px;
  transform: translateY(50%);
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown6 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: -10px;
  /* transform: translateY(100%); */
  transform: translateY(50%);
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown7 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: 0px;
  /* transform: translateY(100%); */
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown8 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: 0px;
  /* transform: translateY(100%); */
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown9 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: 0px;
  /* transform: translateY(100%); */
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown10 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: 0px;
  /* transform: translateY(100%); */
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown_11 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: 0px;
  /* transform: translateY(100%); */
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown_12 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  top: 0;
  /* transform: translateY(50%); */
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown_13 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: -10px;
  transform: translateY(50%);
  /* transform: translateY(100%); */
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown_14 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: -10px;
  transform: translateY(50%);
  /* transform: translateY(100%); */
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown_15 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: -10px;
  transform: translateY(50%);
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown_16 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: -10px;
  transform: translateY(100%);
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown_17 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: -10px;
  transform: translateY(100%);
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown_18 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: -10px;
  transform: translateY(100%);
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown_19 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: -10px;
  transform: translateY(100%);
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown_20 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  bottom: -10px;
  transform: translateY(100%);
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown_21 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  top: -57px;
  /* transform: translateY(100%); */
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown_211 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  top: -57px;
  /* transform: translateY(100%); */
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown_212 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  top: -57px;
  /* transform: translateY(100%); */
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown_22 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  top: -57px;
  /* transform: translateY(100%); */
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown_221 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  top: -57px;
  /* transform: translateY(100%); */
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown_23 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  top: 0;
  /* transform: translateY(100%); */
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.guideDropdown_24 {
  color: var(--SECONDARY-COLOR);
  position: absolute;
  z-index: var(--GUIDE-DROPDOWN-Z-INDEX);
  top: 0;
  /* transform: translateY(100%); */
  width: 100%;
  /* max-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  outline: 1px solid #000;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  box-shadow: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);
}

.explainationContainer {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.500rem;
}

.explainationPoint {
  width: 100%;
  text-align: left;
}

.example {
  display: inline;
  padding: 2px 4px;
  background-color: var(--SENTENCE-TARGET-BGCOLOR);
  border-radius: 0.222rem;
  font-size: 1.75rem !important;
  font-family: var(--TARGET-FF);
  cursor: default;
  margin-bottom: 0.250rem;
}

.helpSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* gap: 0.250rem; */
  width: 100%;
  /* padding: 0.500rem 0; */

}

.helpSection__contents {
  background-color: var(--HELP-SECTION-BGCOLOR);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* gap: 0.750rem; */
  width: 100%;
  /* padding: 0.500rem; */
  /* border-radius: 0.222rem; */
  /* border: var(--FORM-BORDER); */
}

.icon {
  color: var(--PRIMARY-COLOR);
  font-weight: 900;
}

.guideDropdown0>p,
.guideDropdown1>p,
.guideDropdown11>p,
.guideDropdown2>p,
.guideDropdown3>p,
.guideDropdown4>p,
.guideDropdown41>p,
.guideDropdown5>p,
.guideDropdown6>p,
.guideDropdown7>p,
.guideDropdown8>p,
.guideDropdown9>p,
.guideDropdown10>p,
.guideDropdown_11>p,
.guideDropdown_12>p,
.guideDropdown_13>p,
.guideDropdown_14>p,
.guideDropdown_15>p,
.guideDropdown_16>p,
.guideDropdown_17>p,
.guideDropdown_18>p,
.guideDropdown_19>p,
.guideDropdown_20>p,
.guideDropdown_21>p,
.guideDropdown_211>p,
.guideDropdown_212>p,
.guideDropdown_22>p,
.guideDropdown_221>p,
.guideDropdown_23>p,
.guideDropdown_24>p,
.explainationContainer>p,
.helpSection__contents>p {
  font-size: 1rem;
}