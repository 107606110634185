.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 0.555rem 0.555rem 0.555rem;
  background-color: var(--SECONDARY-BGCOLOR);
}

.topBanner {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 0.555rem;
  padding-bottom: 2rem;
}

.exitIcon {
  font-size: 1rem;
  color: var(--SECONDARY-COLOR);
  cursor: pointer;
}

@media (pointer: fine) {
  .exitIcon:hover {
    color: var(--PRIMARY-COLOR);
  }
}