@keyframes slideInFromTop {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 200px;
  }
}

.container {
  animation: slideInFromTop 0.5s linear forwards;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  z-index: var(--TOP-BANNER-Z-INDEX);
  border-bottom: var(--TOP-BANNER-BORDER);
  padding-right: var(--GUTTER);
  padding-left: var(--GUTTER);
}

.loading {
  background-color: var(--PRIMARY-BGCOLOR);
  color: var(--SECONDARY-COLOR);
}

.success {
  background-color: var(--SUCCESS-BGCOLOR);
  color: var(--SUCCESS-PRIMARY-COLOR);
}

.error {
  background-color: #4e3534;
  color: #ffdad6;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.section {
  animation: fadeIn 0.25s 0.25s linear forwards;
  opacity: 0;
  padding-top: 0.888rem;
  padding-bottom: 0.888rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.250rem;
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.500rem;
}

.xmark {
  color: #ff5449;
}

.dismissable {
  cursor: pointer;
}

.check {
  color: var(--SUCCESS-SECONDARY-COLOR);
}

.p {
  text-align: center;
}

.bold {
  font-weight: 900;
}

.link {
  text-decoration: underline;
  color: inherit;
}

.link_success {
  text-decoration: underline;
  color: var(--SUCCESS-SECONDARY-COLOR);
  font-weight: bold;
}

.resend {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
}