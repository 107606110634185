.container {
  min-height: var(--HERO-HEIGHT);
  max-width: var(--CONTENT-MAX-WIDTH);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: var(--CONTENT-TOP-BOTTOM-PADDING) 0.555rem;
  background-color: var(--SECONDARY-BGCOLOR);
  padding-left: var(--GUTTER);
  padding-right: var(--GUTTER);
}

.h2 {
  margin-bottom: 2rem;
}

.SSVh3 {
  color: var(--SECONDARY-COLOR);
  font-size: 1rem;
}

.ttsSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.250rem;
  width: 100%;
  padding: 0.500rem 0;
}

.settingsListContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.h3 {
  color: var(--SECONDARY-COLOR);
  margin-bottom: 0.333rem;
  width: 100%;
  text-align: left;
}

.settingsList {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.333rem;
  max-width: 450px;
  padding: 1rem;
  border-radius: 0.222rem;
  background-color: var(--SETTINGS-LIST-BGCOLOR);
  color: var(--SETTINGS-LIST-COLOR);
  border: 1px solid var(--SETTINGS-LIST-BORDER-COLOR);
}

.setting {
  color: var(--SECONDARY-COLOR);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.settingTxt {
  margin-right: 1rem;
  border-bottom: 1px solid var(--SETTINGS-LIST-BORDER-COLOR);
  padding-bottom: 0.5rem;
  width: 100%;
}

.settingsList .setting:last-child .settingTxt {
  border-bottom: none;
  padding-bottom: 0;
}

.toggleSetting {
  height: 100%;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
}

.settingsList .setting:last-child .toggleSetting {
  padding-bottom: 0;
}

.toggleIcon__on {
  color: var(--PRIMARY-COLOR);
}

.toggleIcon__off {
  color: var(--SECONDARY-COLOR);
}