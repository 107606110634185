.section {
  min-height: var(--HERO-HEIGHT);
  padding: var(--CONTENT-TOP-BOTTOM-PADDING) var(--GUTTER);
  border-radius: 0.222rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.heading {
  margin-bottom: 1rem;
  color: var(--SECONDARY-COLOR);
}

.free {
  color: var(--PRIMARY-COLOR);
}

.username {
  font-weight: bold;
}

.emailVerification {
  font-size: 1rem;
  font-weight: bold;
  color: var(--SECONDARY-COLOR);
}

.consentContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.consent {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  color: var(--SECONDARY-COLOR);
}

.consentText {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}

.recaptchaContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.recaptcha {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0.222rem;
  padding-left: 0.09rem;
  padding-top: 0.09rem;
}

.missingRecaptcha {
  outline: 2px solid var(--ERROR-SECONDARY-COLOR);
  outline-offset: 1px;
}

.termsOfService {
  color: var(--SECONDARY-COLOR);
  display: flex;
  flex-flow: row nowrap;
  gap: 0.200rem;
}

@media (pointer: fine) {
  .termsOfService:hover {
    text-decoration: underline;
  }
}

.login {
  text-align: center;
  font-size: 1.125rem;
  font-weight: lighter;
  margin: 1rem 0;
}

@media (pointer: fine) {
  .link:hover {
    text-decoration: underline;
  }
}

.link:focus {
  outline: 1px solid var(--FOCUS-OUTLINE-COLOR);
  outline-offset: 1px;
}