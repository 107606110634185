.container {
  border: var(--WARNING-BORDER);
  background-color: var(--WARNING-BGCOLOR);
  padding: 1rem;
  /* padding: 1.5rem; */
  border-radius: 0.222rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  margin-bottom: 1rem;
}

.flexColumn {
  max-width: 315.891px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

/* .warningMsg {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0.222rem;
  color: var(--WARNING-SECONDARY-COLOR);
} */

.warningMsg {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 1rem;
  color: var(--WARNING-PRIMARY-COLOR);
  /* margin-bottom: 1rem; */
}