.section {
  max-width: 271.75px;
  min-height: var(--HERO-HEIGHT);
  padding: var(--CONTENT-TOP-BOTTOM-PADDING) 0;
  margin: 0 var(--GUTTER);
  border-radius: 0.222rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.heading {
  margin-bottom: 1rem;
  color: var(--SECONDARY-COLOR);
}

.il {
  color: var(--PRIMARY-COLOR);
  white-space: nowrap;
  font-size: 3rem;
  font-family: var(--TARGET-FF);
}

.passwordRow {
  justify-content: space-between;
}

.forgotPassword {
  font-weight: lighter;
  font-size: 1.125rem;
}

.loginRow {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.signUp {
  text-align: center;
  font-size: 1.125rem;
  font-weight: lighter;
  margin: 1rem 0;
}

@media (pointer: fine) {
  .link:hover {
    text-decoration: underline;
  }
}

.link:focus {
  outline: 1px solid var(--FOCUS-OUTLINE-COLOR);
  outline-offset: 1px;
}