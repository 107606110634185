.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: var(--ERROR-BORDER);
  background-color: var(--ERROR-BGCOLOR);
  color: var(--ERROR-PRIMARY-COLOR);
  border-radius: 0.222rem;
  padding: 1rem;
  width: 100%;
}

.dynaHeight {
  height: var(--dynamic-height);
}

.icon {
  color: var(--ERROR-SECONDARY-COLOR)
}