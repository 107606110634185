.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 0.333rem; */
  width: 100%;
  margin-bottom: 0.5rem;
}

.itemRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0.667rem;
  padding: 1rem;
  /* padding: 0.222rem; */
  border-radius: 0.222rem;
  background-color: var(--TERTIARY-BGCOLOR);
  border: 1px solid var(--PRIMARY-BGCOLOR);
  cursor: pointer;
  width: 100%;
}

.icon {
  color: var(--PRIMARY-COLOR);
  cursor: pointer;
  align-content: right;
}

.infoIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.111rem;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.item {
  cursor: pointer;
  color: var(--SECONDARY-COLOR);
  text-align: left;
  width: 100%;
}

.descriptionContainer {
  border-radius: 0.222rem;
  background-color: var(--TERTIARY-BGCOLOR);
  border: 1px solid var(--PRIMARY-BGCOLOR);
  padding: 1rem;
  /* padding: 0.222rem; */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.444rem;
  width: 100%;
}

.itemInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: var(--SECONDARY-COLOR);
}

.itemName {
  font-weight: 900;
  padding-bottom: 0.222rem;
  width: 100%;
}

.itemDescription {
  color: var(--SECONDARY-COLOR);
  padding: 0.114rem 0.114rem 0.114rem 0.456rem;
  border-radius: 0.222rem;
  font-weight: 100;
  width: 100%;
}

/* @media screen and (min-width: 725px) {
  .descriptionContainer {
    width: 700px;
  }

  .itemRow {
    width: 700px;
  }
} */