/* src/components/Home/HomeV3/HomeV3.module.css */

/* =========================================================
   General Layout and Structure
========================================================= */

.hero {
  position: relative;
  /* Ensure .hero is the positioning context */
  display: flex;
  flex-direction: column;
  min-height: var(--HERO-HEIGHT);
  padding: var(--CONTENT-TOP-BOTTOM-PADDING) var(--GUTTER);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  z-index: 1;
}

.backgroundImage {
  position: absolute;
  /* Changed from fixed to absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  /* Align to top as per original */
  opacity: 0.2;
  /* Maintain original opacity */
  z-index: -1;
  /* Removed transition on position as it's no longer needed */
}

/* =========================================================
   Responsive Design
========================================================= */

@media screen and (min-width: 768px) {
  .backgroundImage {
    /* Already absolute, adjust if necessary */
    object-position: 50% 15%;
  }

  .hookContainer {
    flex-direction: column;
    height: auto;
    z-index: 1;
  }

  .hookImage img {
    height: auto;
    width: auto;
  }
}

@media screen and (min-width: 1024px) {
  .hookContainer {
    flex-direction: row;
    height: auto;
    z-index: 1;
  }
}

/* =========================================================
   Hero Column and Heading Styles
========================================================= */

.heroCol {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  z-index: 1;
}

.heroHeading {
  font-family: var(--TARGET-FF);
  color: var(--SECONDARY-COLOR);
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  z-index: 1;
}

/* =========================================================
   Language Selector Styles
========================================================= */

.languagesContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
}

.languages {
  display: flex;
  align-items: center;
  position: relative;
  /* For positioning dropdowns */
  z-index: 1;
  background-color: var(--SECONDARY-BGCOLOR);
  border-radius: 0.222rem;
  border-style: solid;
  border-width: 4px;
  border-top-color: var(--TERTIARY-BGCOLOR);
  border-left-color: var(--TERTIARY-BGCOLOR);
  border-right-color: var(--PRIMARY-BGCOLOR);
  border-bottom-color: var(--PRIMARY-BGCOLOR);
}

.languages:active {
  border-top-color: var(--PRIMARY-BGCOLOR);
  border-left-color: var(--PRIMARY-BGCOLOR);
  border-right-color: var(--TERTIARY-BGCOLOR);
  border-bottom-color: var(--TERTIARY-BGCOLOR);
}


.languages:hover {
  cursor: pointer;
}

/* =========================================================
   Language Item and Chevron Styles
========================================================= */

.languageItem {
  position: relative;
  display: flex;
  align-items: center;
  /* Vertically centers the flag and chevron */
  margin: 0 0.5rem;
  /* Horizontal spacing between language items */
}

.flag {
  cursor: pointer;
  transition: opacity 0.3s, transform 0.3s;
  font-size: 2.5rem;
  z-index: 1;
}

.arrow {
  font-size: 1.5rem;
  color: var(--SECONDARY-COLOR);
  margin: 0 1rem;
  /* Horizontal spacing between flags */
  z-index: 1;
}

/* =========================================================
   Dropdown Menu Styles
========================================================= */

.dropdown {
  position: absolute;
  top: 100%;
  /* Positions the dropdown directly below the language item */
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  border: 1px solid var(--SECONDARY-COLOR);
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 2.5rem;
  padding: 0.5rem 0;
}

.dropdownItem {
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
  text-align: center;
  font-size: 1.2rem;
}

.dropdownItem:hover {
  background-color: var(--SECONDARY-COLOR);
  color: var(--PRIMARY-BGCOLOR);
}

/* =========================================================
   Demo Component Styles
========================================================= */

.hookContainer {
  width: 100%;
  z-index: 1;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hookImage {
  width: 100%;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.demoTarget {
  grid-area: target;
  background-color: var(--SENTENCE-TARGET-BGCOLOR);
  border-radius: 0.222rem;
  padding: 0.5rem;
  width: 100%;
  flex-grow: 1;
  display: grid;
  place-content: center;
  z-index: 1;
}

.demoToolbar {
  grid-area: toolbar;
  z-index: 1;
}

.heroH2 {
  width: 100%;
  font-size: 2.4rem;
  font-weight: lighter;
  text-align: left;
  color: whitesmoke;
  opacity: 0.3;
  text-shadow: 2px 2px #000;
  z-index: 1;
}

/* =========================================================
   Call-To-Action (CTA) Styles
========================================================= */

.ctaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  /* margin-top: 2rem; */
}

.cta {
  background-color: var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  padding: 1rem 2rem;
  border-radius: 3rem;
  margin-top: 1rem;
  color: var(--SECONDARY-COLOR);
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  z-index: 1;
}

/* .cta:hover {
  background-color: var(--SEEN-END1-COLOR);
  color: var(--PRIMARY-BGCOLOR);
} */

.heroP {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  font-family: var(--TARGET-FF);
  color: var(--TERTIARY-COLOR);
  z-index: 1;
}

/* =========================================================
   Transition Classes
========================================================= */

.fadeIn {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fadeOut {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

/* =========================================================
   Responsive Design
========================================================= */

/* Handled in the main .backgroundImage class and media queries above */

@media screen and (min-width: 768px) {
  .hookContainer {
    flex-direction: column;
    height: auto;
    z-index: 1;
  }

  .hookImage img {
    height: auto;
    width: auto;
  }
}

@media screen and (min-width: 1024px) {
  .hookContainer {
    flex-direction: row;
    height: auto;
    z-index: 1;
  }
}

/* =========================================================
   Accessibility Enhancements
========================================================= */

.languageItem:focus-within .dropdown {
  display: block;
}

.dropdownItem:focus {
  outline: 2px solid var(--PRIMARY-COLOR);
  background-color: var(--SECONDARY-COLOR);
  color: var(--PRIMARY-BGCOLOR);
}