footer {
  width: 100%;
  display: grid;
  place-content: center;
  grid-template-columns: 100%;
  background-color: var(--MAIN-NAV-BGCOLOR);
  color: var(--SECONDARY-COLOR);
  padding: var(--CONTENT-TOP-BOTTOM-PADDING) var(--GUTTER);
  font-size: clamp(0.9rem, 1rem, 3vh);
}


.grid {
  display: grid;
  grid-template-areas:
    "brand brand nav1  nav2"
    "legal legal legal legal";
  gap: 1rem;
}

.brandCol {
  grid-area: brand;
}

.brandLink {
  font-weight: bolder;
  color: var(--SECONDARY-COLOR);
}

.brandName {
  font-family: var(--TARGET-FF);
  font-weight: 900;
  font-size: 1.5rem;
}

.brandSlogan {
  font-weight: lighter;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.socialContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  list-style-type: none;
  text-decoration: none;
  color: var(--PRIMARY-COLOR);
}

.socialItem {
  cursor: pointer;
}

.socialIcon {
  color: var(--PRIMARY-COLOR);
  font-size: 2rem;
}

.nav1Col {
  grid-area: nav1;
}

.nav2Col {
  grid-area: nav2;
}

.navHeader {
  font-size: 1.25rem;
  font-weight: lighter;
  margin-bottom: 1rem;
}

.navList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style-type: none;
  text-decoration: none;
  font-size: 1rem;
}

.link {
  color: var(--SECONDARY-COLOR);
}

@media (pointer: fine) {
  .link:hover {
    text-decoration: underline;
  }
}

.legalRow {
  grid-area: legal;
  border-top: var(--FOOTER-BORDER);
  padding-top: 1.5rem;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.legalList {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  list-style-type: none;
  justify-content: center;
  align-items: center;
}

.copy {
  text-align: center;
  font-size: 1rem;
}