.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--SECONDARY-BGCOLOR);
  color: var(--SECONDARY-COLOR);
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 100%;

  height: 100%;
  flex-grow: 1;
}

.dynaHeight {
  height: var(--dynamic-height);
}