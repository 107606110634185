.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* margin-top: 0.5rem; */
  /* background-color: var(--SECONDARY-BGCOLOR); */
}

.selectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  font-family: var(--TARGET-FF);
  font-style: italic;
  font-weight: bolder;
  /* Removed position: absolute and top: -17px */
  width: 100%;
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 10px;
  /* Add some spacing below */
}


.toolbarContainer {
  pointer-events: auto;
  background: var(--TOOLBAR-BGCOLOR);
  /* border: solid 1px var(--SELECTED-FRAGMENT-OUTLINE-COLOR); */
  outline: solid 1px var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  border-radius: 0.7rem;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px #000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button {
  all: unset;
  border: none;
  border-radius: 0.3em;
  background: none;
  cursor: pointer;
  margin: 5px 5px;
  padding: 10px;
  color: var(--SENTENCE-BTN-COLOR);
}

.button__disabled {
  all: unset;
  border: none;
  border-radius: 0.3em;
  background: none;
  margin: 5px 5px;
  padding: 10px;
  color: var(--SENTENCE-DISABLED-BTN-COLOR);
}

.icon {
  width: 20px;
}

@media (pointer: fine) {
  .button:hover {
    background-color: var(--TOOLBAR-BTN-HOVER-BGCOLOR);
  }
}