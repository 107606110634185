.gridTarget {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--SENTENCE-TARGET-BGCOLOR);
  grid-area: target;
  border-radius: 0.222rem;
  overflow-y: auto;
  margin-top: var(--SENTENCE-CONTENT-MARGIN);
  position: relative;
  overflow: visible;
}

.sentenceContainer {
  padding: 0.5rem;
  width: 100%;
  flex-grow: 1;
  display: grid;
  place-content: center;
  position: relative;
}

.sentence {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: flex-start;
  gap: 0.444rem;
  font-family: var(--TARGET-FF);
  list-style-type: none;
  margin: 0px 4px 0px 4px;
  padding: 0;
}

.paragraphIndentation {
  margin-left: 32px;
}

.translation {
  text-align: center;
  font-size: 1rem;
  opacity: 0.95;
  color: lightcyan;
}

.punctuation {
  background-color: var(--SENTENCE-TARGET-BGCOLOR);
}

.wordInstance {
  font-size: 1.75rem;
  font-family: var(--TARGET-FF);
  cursor: default;
}

.wordInstanceText {
  cursor: pointer;
  text-align: center;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.languageRow {
  margin: 0;
  padding: 0;
  line-height: 0.3;
}

.wordInstanceLanguage {
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 0.555rem;
  font-style: italic;
  color: var(--BORDER-COLOR);
  /* color: var(--SELECTED-WORD-BGCOLOR); */
}

/* .wordInstanceText:hover {
  background-color: var(--TARGET-WORD-HOVER-COLOR);
} */

.selectedWord {
  background-color: var(--SELECTED-WORD-BGCOLOR);
}

.fragment {
  border-radius: 0.09rem;
}

@media (pointer: fine) {
  .fragment:hover {
    outline: 1px solid #a5a5a5;
    border-radius: 0.090rem;
  }

  .fragment.selectedFragment:hover {
    outline: 1px solid var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  }
}

.selectedFragment {
  outline: 1px solid var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
}

.spacer {
  flex-grow: 1;
  min-width: 0px;
  height: 0px;
}

.icon {
  /* width: 20px; */
}