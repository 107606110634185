.container {
  padding: var(--CONTENT-TOP-BOTTOM-PADDING) var(--GUTTER);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;
}

.companyName {
  font-family: var(--TARGET-FF);
  font-size: 2.25rem;
}

.icon {
  font-size: 2.5rem;
  color: var(--WARNING-BGCOLOR);
}