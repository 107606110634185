.section {
  padding: var(--CONTENT-TOP-BOTTOM-PADDING) var(--GUTTER);
  border-radius: 0.222rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.container {
  background-color: var(--WARNING-BGCOLOR);
  padding: 1.5rem;
  border-radius: 0.222rem;
  border: var(--WARNING-BORDER);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 0.5rem;
}

.warningMsg {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 1rem;
  color: var(--WARNING-PRIMARY-COLOR);
  margin-bottom: 1rem;
}

.return {
  color: var(--ERROR-PRIMARY-COLOR);
  font-weight: bold;
  text-decoration: underline;
}