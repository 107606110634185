.container {
  position: relative;
  width: 100%;
}

.oldLayer,
.newLayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.newLayer {
  position: relative;
}

.staggerItem {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 500ms ease-out, transform 500ms ease-out;
}

.oldShow {
  opacity: 1;
  transform: translateY(0);
}

.oldHide {
  opacity: 0;
  transform: translateY(-10px);
}

.newShow {
  opacity: 1;
  transform: translateY(0);
}

.newHide {
  opacity: 0;
  transform: translateY(10px);
}