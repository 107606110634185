.gridCenter {
  align-items: center;
}

.container {
  min-height: var(--HERO-HEIGHT);
  max-width: var(--CONTENT-MAX-WIDTH);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  padding: var(--CONTENT-TOP-BOTTOM-PADDING) 0.555rem;
  background-color: var(--SECONDARY-BGCOLOR);
  padding-left: var(--GUTTER);
  padding-right: var(--GUTTER);
}

.h2 {
  margin-bottom: 2rem;
  font-family: (--TARGET-FF);
  /* text-align: center; */
}

.list {
  display: grid;
  grid-template-columns: 45vw 45vw;
  gap: calc((100vw - 2*45vw)/3);
  /* Equal spacing between rows and columns */
  list-style-type: none;
  margin: auto;
  padding: 0;
  /* Optional: Remove default padding from lists */
  text-decoration: none;
  width: 100%;
  justify-content: center;
}

@media screen and (min-width: 700px) {
  .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 320px);
    gap: 1rem;
    /* Equal spacing between rows and columns */
    list-style-type: none;
    margin: auto;
    padding: 0;
    /* Optional: Remove default padding from lists */
    text-decoration: none;
    width: auto;
    justify-content: center;
  }
}