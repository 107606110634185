.container {
  padding: var(--CONTENT-TOP-BOTTOM-PADDING) var(--GUTTER);
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.headerRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
}

.h2 {
  width: 100%;
  border-radius: 0.222rem;
  background-color: var(--TERTIARY-BGCOLOR);
  color: var(--SECONDARY-COLOR);
  margin-left: 1rem;
  padding: 0.25rem;
}

.errMsg {
  color: var(--ERROR-PRIMARY-COLOR);
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 0.5rem 1rem;
}

.loadingContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem 0.5rem 1rem;
}

.successMsg {
  color: var(--VALID-PRIMARY-COLOR);
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 0.5rem 1rem;
}

.successMsgDetails {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.111rem;
  color: var(--SECONDARY-COLOR);
}

.icon {
  width: 16px;
  height: 16px;
  color: var(--TERTIARY-BGCOLOR);
}

.dragAndDrop {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  border: var(--FORM-BORDER);
  width: 100%;
}