.container {
  min-height: var(--HERO-HEIGHT);
  max-width: var(--CONTENT-MAX-WIDTH);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  gap: 0.5rem;
  padding: var(--CONTENT-TOP-BOTTOM-PADDING) 0.555rem;
  background-color: var(--SECONDARY-BGCOLOR);
}

.h2 {
  margin-bottom: 1rem;
}

.reportIssue {
  color: var(--SECONDARY-COLOR);
  text-align: left;
  font-size: 1.125rem;
  font-weight: lighter;
  margin: 1rem 0 0 0;
}