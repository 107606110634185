.container {
  font-size: 1.25rem;
  z-index: var(--MAIN-NAV-Z-INDEX);
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: var(--MAIN-NAV-PADDING);
  padding-left: var(--GUTTER);
  padding-right: var(--GUTTER);
  background-color: var(--MAIN-NAV-BGCOLOR);
  color: var(--MAIN-NAV-INACTIVE-COLOR);
  border-bottom: var(--MAIN-NAV-BORDER);
  box-shadow: var(--MAIN-NAV-SHADOW);
}

.topRow {
  display: grid;
  grid-template-areas: "top0 top1 top2";
  grid-template-columns: auto 1fr auto;
  width: 100%;
  padding-bottom: var(--MAIN-NAV-PADDING);
}

/** || LOGO STYLES */
.logoContainer {
  position: relative;
  grid-area: top0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1.5rem;
}

.badgeWrapper {
  position: relative;
  margin-right: 1.5rem;
}

/** || HAMBURGER STYLES */
.hamburgerContainer {
  grid-area: top1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.hamburger {
  border: none;
  background-color: transparent;
  color: var(--MAIN-NAV-INACTIVE-COLOR);
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: grid;
  place-content: center;
  font-size: 1rem;
}

@media (pointer: fine) {
  .hamburger:hover {
    color: var(--MAIN-NAV-ACTIVE-COLOR);
  }
}

/** || DESKTOP MENU STYLES */
.desktopMenuContainer {
  grid-area: top1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
}

.desktopMenuList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.desktopMenuItem {
  color: var(--MAIN-NAV-INACTIVE-COLOR);
  padding: var(--MAIN-NAV-LINK-PADDING);
  border-radius: var(--MAIN-NAV-LINK-BORDER-RADIUS);
}

@media (pointer: fine) {
  .menuItem:hover {
    background-color: var(--SECONDARY-BGCOLOR);
    color: var(--PRIMARY-COLOR);
  }
}

.active {
  background-color: var(--SECONDARY-BGCOLOR);
  color: var(--PRIMARY-COLOR);
}

/** || MOBILE MENU STYLES */
.drawer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.mobileMenu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.mobileMenu_item {
  width: 100%;
  border-top: var(--MAIN-NAV-DRAWER-BORDER);
}

.mobileMenu_link {
  color: var(--MAIN-NAV-INACTIVE-COLOR);
}

.mobileMenu_link_row {
  background-color: transparent;
  padding-top: 0.222rem;
  padding-bottom: 0.222rem;
  padding-left: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

@media (pointer: fine) {
  .mobileMenu_link_row:hover {
    background-color: var(--SECONDARY-BGCOLOR);
    color: var(--PRIMARY-COLOR);
  }
}

/* mobile view */
@media screen and (min-width: 501px) {
  .container {
    flex-direction: row;
    gap: 1rem;
  }

  .hamburger {
    display: none;
  }

  .menu {
    display: unset;
  }
}

.subAuthContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

/** || SUBSCRIBE STYLES */
.subscribeContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  padding: 0.4rem;
  border-radius: 0.222rem;
  background-color: var(--SUBSCRIBE-BUTTON-BGCOLOR);
  color: var(--TERTIARY-COLOR);
  cursor: pointer;
}

.subscribeButton {
  border: none;
  padding: 0;
  background: none;
  background-color: transparent;
  border-radius: 0.222rem;
}

.subscribeIcon {
  font-size: 1.5rem;
}

.subscribeText {
  font-family: var(--TARGET-FF);
  font-size: 1.25rem;
  font-weight: bolder;
}

/** || AUTH STYLES */
.authContainer {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}

.login {
  color: var(--MAIN-NAV-INACTIVE-COLOR);
}

@media (pointer: fine) {
  .login:hover {
    text-decoration: underline;
  }
}

.signUp {
  background-color: var(--SECONDARY-BGCOLOR);
  color: var(--SECONDARY-COLOR);
  padding: 0.4rem;
  border-radius: 0.222rem;
}

@media (pointer: fine) {
  .signUp:hover {
    outline: 1px solid var(--PRIMARY-COLOR);
    color: var(--PRIMARY-COLOR);
  }
}

.userIcon {
  color: var(--BORDER-COLOR);
}

.userIcon__sub {
  color: var(--NEW-STEM-COLOR);
}

.username {
  color: var(--SECONDARY-COLOR);
}

.username__sub {
  color: var(--PRIMARY-COLOR);
}

.userLink {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  padding: var(--MAIN-NAV-LINK-PADDING);
  border-radius: var(--MAIN-NAV-LINK-BORDER-RADIUS);
}

@media (pointer: fine) {
  .userLink:hover {
    background-color: var(--SECONDARY-BGCOLOR);
  }
}