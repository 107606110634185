.container {
  position: relative;
  width: 31.797px;
  height: 50px;
}

.logo {
  white-space: nowrap;
  font-family: var(--TARGET-FF);
  font-size: 3.5rem;
  color: var(--SEEN-STEM-COLOR);
  letter-spacing: 2px;
  position: absolute;
  bottom: -8.5px;
}

.textOutline {
  color: var(--SEEN-END1-COLOR);
  position: relative;
  display: inline-block;
  line-height: 80%;
}

.textOutline::after {
  content: '';
  position: absolute;
  top: -4px;
  right: 0px;
  bottom: -1px;
  left: -2px;
  border: solid 1px var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  pointer-events: none;
  border-radius: 0.222rem;
}