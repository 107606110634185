.levelContainer {
    position: relative;
}

.prevLevel {
    position: relative;
    z-index: 1;
    transition: opacity 1s ease-out;
}

.level {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 1s ease-out;
}

.fadeIn {
    animation: fadeIn 3s ease-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }

    66% {
        opacity: 0;
    }

    100% {
        opacity: 1
    }
}

.fadeOut {
    animation: fadeOut 3s ease-out;
}

@keyframes fadeOut {
    0% {
        opacity: 1
    }

    33% {
        opacity: 0;
    }

    100% {
        opacity: 0
    }
}