.appLayout {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.rowContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  flex-grow: 1;
  position: relative;
}

.outletContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.drawer {
  height: 100%;
  max-height: 100vh;
  border-left: 1px solid var(--FORM-BORDER-COLOR);
}

.drawerDeviceStyles {
  /** mobile */
  width: 300px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: var(--SSV-DRAWER-Z-INDEX);
  background-color: var(--SECONDARY-BGCOLOR);
}

/** adjust for drawer content width */
@media screen and (min-width: 645px) {
  .drawerDeviceStyles {
    /** tablet & desktop */
    width: 475px;
    position: relative;
    z-index: var(--SSV-DRAWER-Z-INDEX);
    background-color: var(--SECONDARY-BGCOLOR);
  }
}

.notificationContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  z-index: var(--TOP-BANNER-Z-INDEX);
  background-color: var(--BETA-NOTIFICATION-COLOR);
  color: var(--TERTIARY-BGCOLOR);
  border-bottom: var(--TOP-BANNER-BORDER);
  padding-top: 0.444rem;
  padding-bottom: 0.444rem;
  padding-right: var(--GUTTER);
  padding-left: var(--GUTTER);
}

.link {
  color: #000
}

@media (pointer: fine) {
  .link:hover {
    text-decoration: underline;
  }
}

.contributionIcon {
  padding: 0 0.25rem;
}

.spacerIcon {
  z-index: 999;
  color: var(--SECONDARY-BGCOLOR);
  visibility: hidden;
}

.exitIcon {
  z-index: 999;
  color: var(--SECONDARY-BGCOLOR);
  cursor: pointer;
}