/* QuickStart.module.css */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
}

.container {
    width: 90vw;
    max-width: 750px;
    background-color: var(--SECONDARY-BGCOLOR);
    border-radius: 8px;
    padding: 2rem;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 75vh;
    overflow-y: hidden;
    overflow: auto;
}

.container::-webkit-scrollbar {
    display: none;
}

.closeButton {
    position: fixed;
    top: 25vh;
    left: 5vw;
    background: none;
    border: none;
    color: var(--TERTIARY-BGCOLOR);
    cursor: pointer;
    font-size: 1.5rem;
    padding: 0.5rem;
}

.closeButton:hover {
    color: var(--SECONDARY-COLOR);
}

.content {
    color: var(--BORDER-COLOR);
    font-family: var(--FF);
}

.content h2 {
    margin-bottom: 3rem;
    font-size: 1.8rem;
    color: var(--BORDER-COLOR);
    text-align: center;
    font-family: var(--TARGET-FF);
}

/* Make the main instructions an ordered list with improved spacing */
.instructions {
    /* margin: 1.5rem 0; */
    padding: 0;
    list-style: none;
}

/* Each step is displayed with a step number on the left */
.step {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.stepName {
    min-width: 2rem;
    font-weight: bold;
    color: var(--BORDER-COLOR);
    font-size: 1.2rem;
    font-family: var(--TARGET-FF);
}

.stepText {
    font-size: 1.2rem;
    color: var(--SECONDARY-COLOR);
    border-bottom: 1px solid var(--TERTIARY-BGCOLOR)
}

.stepTitle {
    font-weight: bold;
    margin-bottom: 1rem;
}

.subTextTitle {
    font-size: 1rem;
    color: var(--BORDER-COLOR);
    font-weight: bold;
}

.subText {
    font-size: 1rem;
    color: var(--BORDER-COLOR);
    margin-bottom: 1rem;
}

.caption {
    font-size: .8rem;
    color: var(--BORDER-COLOR);
    margin-bottom: 1rem;
    line-height: 1.2rem;
}

/* Sub-list under the steps (e.g. "new fragments look like this") */
.subList {
    list-style-type: disc;
    margin: 0.5rem 0 0 1.5rem;
    padding: 0;
}

.callout {
    /* margin-top: 2rem; */
    font-weight: 600;
    /* background-color: var(--SECONDARY-COLOR); */
    text-align: center;
    /* padding: 1rem; */
    border-radius: 4px;
    color: var(--NEW-STEM-COLOR);
    font-family: var(--TARGET-FF);
}

.example {
    display: inline;
    padding: 2px 4px;
    background-color: var(--SENTENCE-TARGET-BGCOLOR);
    border-radius: 0.222rem;
    font-size: 1.75rem;
    font-family: var(--TARGET-FF);
    cursor: default;
}

.icon {
    font-size: 5rem;
    color: var(--BORDER-COLOR);
}