.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* max-width: 315px; */
}

.positionToolbar {
  position: absolute;
  bottom: 1px;
}

.colorPatch {
  position: absolute;
  top: -74px;
  width: 8px;
  height: 12px;
  background-color: var(--SECONDARY-BGCOLOR);
  /** FOR DEV */
  /* border: 1px solid red; */
}

.selectionContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  font-family: var(--TARGET-FF);
  font-style: italic;
  font-weight: bolder;
  position: absolute;
  top: -17px;
  width: 100%;
  text-align: center;
  font-size: 1.4rem;
  left: 0;

}

.selectionText {
  background-color: var(--TOOLBAR-BGCOLOR);
  border-radius: 0.444rem;
  padding: 1px 5px;
  border: 1px solid var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
}


.toolbarContainer {
  pointer-events: auto;
  background: var(--TOOLBAR-BGCOLOR);
  /* border: solid 1px var(--SELECTED-FRAGMENT-OUTLINE-COLOR); */
  outline: solid 1px var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  border-radius: 0.7rem;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px #000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button {
  all: unset;
  border: none;
  border-radius: 0.3em;
  background: none;
  cursor: pointer;
  margin: 5px 5px;
  padding: 10px;
  color: var(--SENTENCE-BTN-COLOR);
  background-color: var(--TOOLBAR-BGCOLOR);
}

.button__disabled {
  all: unset;
  border: none;
  border-radius: 0.3em;
  background: none;
  margin: 5px 5px;
  padding: 10px;
  color: var(--SENTENCE-DISABLED-BTN-COLOR);
  background-color: var(--TOOLBAR-BGCOLOR);
}

.icon {
  width: 20px;
}

@media (pointer: fine) {
  .button:hover {
    background-color: var(--TOOLBAR-BTN-HOVER-BGCOLOR);
  }
}