/* QuickStart.module.css */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
}

.container {
    width: 90vw;
    max-width: 750px;
    background-color: var(--SECONDARY-BGCOLOR);
    border-radius: 8px;
    padding: 2rem;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 75vh;
    overflow-y: hidden;
    overflow: auto;
}

.scrollContent::-webkit-scrollbar {
    display: none;
}

.scrollContent {
    position: relative;
    height: 100%;
    overflow: auto;
    /* Apply a mask that fades out the bottom */
    -webkit-mask-image: linear-gradient(to bottom,
            black 70%,
            transparent 100%);
    mask-image: linear-gradient(to bottom,
            black 70%,
            transparent 100%);
}

.cover {
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
}

.rightTitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: var(--TARGET-FF);
    text-align: center;
}

.linkContainer {
    font-family: var(--TARGET-FF);
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 7rem;
}

.available {
    background-color: var(--NEW-STEM-COLOR);
    padding: 0.4rem;
    border-radius: 0.222rem;
    color: var(--TERTIARY-COLOR);
    font-weight: bolder;
    font-size: 1.25rem;
    gap: 0.25rem;
    display: flex;
    width: fit-content;
}

.figcaption {
    padding: 8px;
    text-decoration: none;
    color: var(--SECONDARY-COLOR);
    display: flex;
    flex-direction: column;
}

.lessonHeader {
    color: var(--SECONDARY-COLOR);
    font-size: 2rem;
}

.chapterTitle {
    font-size: 1.125rem;
    font-weight: lighter;
    color: var(--BORDER-COLOR);
}

.bookTitle {
    font-style: italic;
}

.lessonAuthor {
    color: var(--BORDER-COLOR);
    font-size: 1rem;
    margin-top: 1rem;
}

.figcaption_vocabRow {
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-evenly;
    font-weight: bold;
    margin-top: 0.25rem;
}

.percentagesRow {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    font-weight: lighter;
    padding: 0.25rem 1rem;
    background-color: var(--SENTENCE-TARGET-BGCOLOR);
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    flex-grow: 1;
    width: 100%;
}

.figcaption_vocabFlag {
    display: block;
    height: 0.8rem;
    width: auto;
    opacity: 0.75;
}

.figcaption_vocab__new,
.figcaption_vocab__seen,
.figcaption_vocab__known {
    font-size: 0.8rem;
}

.figcaption_vocab__new {
    color: var(--NEW-STEM-COLOR);
}

.figcaption_vocab__seen {
    color: var(--SEEN-STEM-COLOR);
}

.figcaption_vocab__known {
    color: var(--PRIMARY-COLOR);
}

@media screen and (min-width: 500px) {
    .lessonCoverContainer {
        max-height: 45vh;
    }
}

/* Tablet, desktop */
@media screen and (min-width: 650px) {
    .lessonHeader {
        color: var(--SECONDARY-COLOR);
        font-size: 3rem;
    }

    .chapterTitle {
        font-size: 1.5rem;
        font-weight: lighter;
        color: var(--BORDER-COLOR);
    }

    .lessonAuthor {
        margin-top: 1rem;
        color: var(--BORDER-COLOR);
        font-size: 1rem;
    }

    .figcaption {
        text-decoration: none;
        color: var(--PRIMARY-COLOR);
        font-size: 0.85rem;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        padding: 8px;
    }

    .figcaption_vocabFlag {
        display: block;
        height: 1.25rem;
        width: auto;
        opacity: 0.75;
    }

    .figcaption_vocab__new,
    .figcaption_vocab__seen,
    .figcaption_vocab__known {
        font-size: 1.25rem;
    }

    .comingSoon {
        font-size: 2.5rem;
        font-weight: bolder;
        text-align: center;
    }

    .comingSoonIcon {
        font-size: 3rem;
    }

    .subscribe {
        font-size: 2.5rem;
        font-weight: bolder;
        text-align: center;
    }

    .subscribeIcon {
        font-size: 3rem;
    }
}

.closeButton {
    position: fixed;
    top: 25vh;
    left: 5vw;
    background: none;
    border: none;
    color: var(--TERTIARY-BGCOLOR);
    cursor: pointer;
    font-size: 1.5rem;
    padding: 0.5rem;
}

.closeButton:hover {
    color: var(--SECONDARY-COLOR);
}

.content {
    color: var(--BORDER-COLOR);
    font-family: var(--FF);
}

.content h2 {
    margin-bottom: 3rem;
    font-size: 1.8rem;
    color: var(--BORDER-COLOR);
    text-align: center;
    font-family: var(--TARGET-FF);
}

.instructions {
    padding: 0;
    list-style: none;
}

.step {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.stepName {
    min-width: 2rem;
    font-weight: bold;
    color: var(--BORDER-COLOR);
    font-size: 1.2rem;
    font-family: var(--TARGET-FF);
}

.stepText {
    font-size: 1.2rem;
    color: var(--SECONDARY-COLOR);
    border-bottom: 1px solid var(--TERTIARY-BGCOLOR);
}

.stepTitle {
    font-weight: bold;
    margin-bottom: 1rem;
}

.subTextTitle {
    font-size: 1rem;
    color: var(--BORDER-COLOR);
    font-weight: bold;
}

.subText {
    font-size: 1rem;
    color: var(--SECONDARY-COLOR);
    margin-bottom: 1rem;
    text-align: justify;
    font-family: var(--TARGET-FF);
}

.caption {
    font-size: 0.8rem;
    color: var(--BORDER-COLOR);
    margin-bottom: 1rem;
    line-height: 1.2rem;
}

.subList {
    list-style-type: disc;
    margin: 0.5rem 0 0 1.5rem;
    padding: 0;
}

.callout {
    font-weight: 600;
    text-align: center;
    border-radius: 4px;
    color: var(--NEW-STEM-COLOR);
    font-family: var(--TARGET-FF);
}

.example {
    display: inline;
    padding: 2px 4px;
    background-color: var(--SENTENCE-TARGET-BGCOLOR);
    border-radius: 0.222rem;
    font-size: 1.75rem;
    font-family: var(--TARGET-FF);
    cursor: default;
}

.icon {
    font-size: 5rem;
    color: var(--BORDER-COLOR);
}

/* Scroll indicator styling */
.scrollIndicator {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--BORDER-COLOR);
    font-size: 2rem;
    pointer-events: none;
    animation: bounce 1.5s infinite;
}

/* @keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateX(-50%) translateY(0);
    }

    40% {
        transform: translateX(-50%) translateY(-10px);
    }

    60% {
        transform: translateX(-50%) translateY(-5px);
    }
} */