.grid {
  height: 100%;
  min-height: 750px;
  flex-grow: 1;
  padding: var(--SENTENCE-TOP-PADDING) var(--SENTENCE-GUTTER) 0 var(--SENTENCE-GUTTER);
  display: grid;
  grid-template-rows: auto repeat(7, 1fr);
  grid-template-columns: 1fr auto 1fr;
  max-height: var(--dynamic-height);
  /* overflow: hidden; */
  /** must be able to scroll in tutorial bc overlays have buttons */
  overflow-y: auto;
  grid-template-areas:
    "header0 header1 header2"
    "target target target"
    "target target target"
    "target target target"
    "target target target"
    "info info info"
    "info info info"
    "info info info";
}

.overlay {
  height: 100%;
  flex-grow: 1;
  position: absolute;
  z-index: var(--OVERLAY-Z-INDEX);
  top: 0;
  bottom: 0;
  pointer-events: none;
  width: 100%;
  /* max-width: 1440px; */
  display: grid;
  place-content: end center;
}

.focusOverlay {
  height: 100%;
  flex-grow: 1;
  position: absolute;
  z-index: var(--GUIDE-FOCUS-Z-INDEX);
  top: 0;
  bottom: 0;
  pointer-events: none;
  width: 100%;
  /* max-width: 1440px; */
  display: grid;
  place-content: end center;
}

.foucsOverlay {
  height: 100%;
  flex-grow: 1;
  position: absolute;
  z-index: var(--GUIDE-FOCUS-Z-INDEX);
  top: 0;
  bottom: 0;
  pointer-events: none;
  width: 100%;
  /* max-width: 1440px; */
  display: grid;
  /* place-content: end center; */
}