.container {
  font-size: 1.25rem;
  width: 100%;
  text-align: center;
  z-index: var(--TOP-BANNER-Z-INDEX);
  background-color: var(--TOP-BANNER-BGCOLOR);
  color: var(--PRIMARY-COLOR);
  border-bottom: var(--TOP-BANNER-BORDER);
  padding-top: var(--TOP-BANNER-PADDING);
  padding-bottom: var(--TOP-BANNER-PADDING);
  padding-right: var(--GUTTER);
  padding-left: var(--GUTTER);
}

@media (pointer: fine) {
  .link:hover {
    text-decoration: underline;
  }
}