.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 0.555rem 0.555rem 0.555rem;
  background-color: var(--SECONDARY-BGCOLOR);
  overflow-y: auto;
}

.topBanner {
  background-color: var(--SECONDARY-BGCOLOR);
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 0.555rem;
  padding-bottom: 2rem;
}

.icon,
.exitIcon {
  font-size: 1rem;
  color: var(--SECONDARY-COLOR);
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.icon {
  margin-right: 1rem;
}

@media (pointer: fine) {
  .exitIcon:hover {
    color: var(--PRIMARY-COLOR);
  }
}

.tour {
  width: 100%;
  margin-bottom: 1rem;
  text-align: center;
  /* background-color: var(--SELECTED-FRAGMENT-OUTLINE-COLOR); */
}

.tourButton {
  /* display: inline; */
  /* background-color: var(--SECONDARY-COLOR); */
  border: none;
  background-color: var(--BETA-NOTIFICATION-COLOR);
  padding: 1rem;
  font-weight: 600;
  border-radius: 2.222rem;
  font-size: 1.250rem;
  cursor: pointer;
  color: #000;
}

.helpList {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.444rem;
  min-height: 0;
  overflow-y: auto;
}

.helpList__colItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  color: var(--SECONDARY-COLOR);
  padding: 0.250rem;
  border-radius: 0.222rem;
}

.h3Row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
}

.h3 {
  width: 100%;
  border-radius: 0.222rem;
  background-color: var(--TERTIARY-BGCOLOR);
  color: var(--SECONDARY-COLOR);
  padding: 0.25rem;
}

.vocabularyHelp,
.navigationHelp {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.750rem;
}

.bulletedList {
  font-size: 1.25rem;
  list-style-type: disc;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.250rem;
}

.example {
  display: inline;
  padding: 2px 4px;
  background-color: var(--SENTENCE-TARGET-BGCOLOR);
  border-radius: 0.222rem;
  font-size: 1.75rem;
  font-family: var(--TARGET-FF);
  cursor: default;
}

.bottomPadding {
  padding-bottom: var(--GUTTER);
}

.helpSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.250rem;
  width: 100%;
  padding: 0.500rem 0;
}

.helpSection__contents {
  background-color: var(--HELP-SECTION-BGCOLOR);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.750rem;
  width: 100%;
  padding: 0.500rem;
  border-radius: 0.222rem;
  border: var(--FORM-BORDER);
}