.gridTarget {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--PRIMARY-BGCOLOR);
  grid-area: target;
  border-radius: 0.222rem;
  overflow-y: auto;
  margin-top: var(--SENTENCE-CONTENT-MARGIN);
  overflow-x: hidden;
  /* outline: 1px solid var(--SELECTED-FRAGMENT-OUTLINE-COLOR); */
  position: relative
}

.gridTarget::-webkit-scrollbar {
  display: none
}

.sentenceContainer {
  position: relative;
  padding: 0 8px;
  width: 100%;
  flex-grow: 1;
  display: grid;
  place-content: center;
  border-radius: .222rem;
  /* border: solid transparent 2px; */
  height: 100%;
  overflow: visible
}

.sentenceContainer::before {
  background-image: var(--background-image);
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0.15;
  z-index: 0;
  animation: drift 600s linear infinite;
  will-change: background-position;
}

@media (min-width: 1440px) {
  .sentenceContainer::before {
    width: 100%;
  }
}

.sentence {
  opacity: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: flex-start;
  gap: 0.444rem;
  font-family: var(--TARGET-FF);
  list-style-type: none;
  margin: 0px 4px 0px 4px;
  z-index: 1;
  padding-bottom: 60px;
}

ol {
  overflow-y: auto
}

ol::-webkit-scrollbar {
  display: none
}

.paragraphIndentation {
  margin-left: 32px;
}

.translation {
  text-align: center;
  font-size: 1rem;
  opacity: 0;
  color: var(--BORDER-COLOR);
  transition: opacity 500ms ease-out;
}

.translation.visible {
  opacity: 1;
}

.punctuation {
  color: var(--SECONDARY-COLOR);
  /* The same transitions as .fragment */
  border-radius: 0.09rem;
  outline: 1px solid transparent;
  transition: color 500ms ease-out, outline 500ms ease-out;
  opacity: 0;
  /* Start invisible */
}

.punctuation.animate {
  animation: fadeIn 2s ease-out;
}

.wordInstance {
  font-size: 1.75rem;
  font-family: var(--TARGET-FF);
  cursor: default;
}

.wordInstanceText {
  cursor: pointer;
  text-align: center;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.languageRow {
  margin: 0;
  padding: 0;
  line-height: 0.3;
}

.modernEquivalentRow {
  margin: 0;
  padding: 0;
  text-align: left;
  line-height: 0.8;
}

.wordInstanceLanguage {
  margin: 0;
  padding: 0;
  /* text-align: left; */
  font-size: 0.555rem;
  font-style: italic;
  color: var(--BORDER-COLOR);
  /* color: var(--SELECTED-WORD-BGCOLOR); */
}

.wordInstanceModernEquivalent {
  margin: 0;
  padding: 0;
  text-align: left;
  /* font-size: 0.555rem; */
  font-size: 0.888rem;
  font-style: italic;
  color: var(--BORDER-COLOR);
  /* color: var(--SELECTED-WORD-BGCOLOR); */
}

/* .wordInstanceText:hover {
  background-color: var(--TARGET-WORD-HOVER-COLOR);
} */

/* .selectedWord {
  background-color: var(--SELECTED-WORD-BGCOLOR);
} */

.fragment {
  border-radius: 0.09rem;
  outline: 1px solid transparent;
  transition: color 500ms ease-out, outline 500ms ease-out;
  opacity: 0
}

.fragment.animate {
  animation: fadeIn 2s ease-out;
}

@media (pointer: fine) {
  .fragment:hover {
    outline: 1px solid #a5a5a5;
    border-radius: 0.090rem;
  }

  .fragment.selectedFragment:hover {
    outline: 1px solid var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  }
}

.selectedFragment {
  outline: solid 1px var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  border-radius: 0.090rem;
}

.spacer {
  flex-grow: 1;
  min-width: 0px;
  height: 0px;
}

@keyframes drift {
  0% {
    background-position: center top;
  }

  50% {
    background-position: center bottom;
  }

  100% {
    background-position: center top;
  }
}

.fadeOut {
  animation: fadeOut 500ms linear forwards;
  /* ensures it stays hidden after finishing */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    transform: translateY(10px);
  }
}

.fadeIn {
  animation: fadeIn 2s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeInItem {
  opacity: 0;
  /* start invisible */
  animation-name: fadeInItem;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

@keyframes fadeInItem {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}