.gridInfo {
  display: grid;
  grid-template-areas:
    "info_header info_header info_header"
    "info_content info_content info_content";
  grid-template-rows: auto 1fr;
  grid-area: info;
  margin-top: var(--SENTENCE-CONTENT-MARGIN);
  border-top: 1px solid var(--BORDER-COLOR);
}

.gridInfo_content {
  grid-area: info_content;
  display: grid;
  width: 100%;
  grid-template-areas: "translation grammar"
    "translation grammar";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  overflow-y: auto;
  margin-bottom: 3.71875rem;

  margin-top: var(--SENTENCE-CONTENT-MARGIN);
  margin-bottom:
    0;
  scroll-padding-bottom: 3.71875rem;
}

.gridInfo_content__noScroll {
  grid-area: info_content;
  display: grid;
  width: 100%;
  grid-template-areas: "translation grammar"
    "translation grammar";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  /* overflow-y: auto; */
  overflow-y: hidden;
  margin-bottom: 3.71875rem;

  margin-top: var(--SENTENCE-CONTENT-MARGIN);
  margin-bottom:
    0;
  /* scroll-padding-bottom: 3.71875rem; */
}

.translationCol {
  grid-area: translation;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid var(--BORDER-COLOR);
  padding-right: 10px;
  background-color: var(--SECONDARY-BGCOLOR);
}

.translationCol__focus {
  grid-area: translation;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  /* overflow-y: hidden; */
  overflow-x: hidden;
  gap: 0.500rem;
  padding-left: 10px;
  background-color: var(--SECONDARY-BGCOLOR);
}

.header {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: var(--SECONDARY-BGCOLOR);
  padding-bottom: 4px;
  font-weight: 900;
}

.header__off {
  color: var(--SECONDARY-COLOR);
}

.headerToggle,
.headerTTSButton {
  cursor: pointer;
}

.headerToggle {
  position: absolute;
  left: 0;
}

.headerTTSButton {
  position: absolute;
  right: 0;
  color: var(--PRIMARY-COLOR);
}

.sentenceTranslation {
  font-size: 1.2rem;
  line-height: 150%;
  text-align: left;
  padding-bottom: 76px;
}

@media screen and (min-width: 500px) {
  .sentenceTranslation {
    text-align: justify;
  }
}

.grammarCol {
  grid-area: grammar;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 0.500rem;
  padding-left: 10px;
  /* background-color: var(--SECONDARY-BGCOLOR); */
}

.grammarCol__focus {
  grid-area: grammar;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  /* overflow-y: hidden; */
  overflow-x: hidden;
  gap: 0.500rem;
  padding-left: 10px;
  background-color: var(--SECONDARY-BGCOLOR);
}

.grammarCol__focus__noScroll {
  grid-area: grammar;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  /* overflow-y: hidden; */
  overflow-x: hidden;
  gap: 0.500rem;
  padding-left: 10px;
  background-color: var(--SECONDARY-BGCOLOR);
}

.grammarTable {
  display: block;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  font-size: 1.2rem;
  padding-bottom: 76px;
}

.grammarItem {
  color: var(--SECONDARY-COLOR);
}

.grammarItem__selected {
  color: var(--PRIMARY-COLOR);
  text-decoration: underline;
  text-decoration-color: var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
}