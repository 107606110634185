/** || RESET */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/** || VARIABLES */
:root {
  /** HEIGHT FIX */
  --dynamic-height: 100vh;
  --HERO-HEIGHT: calc(100vh - 70.203px);

  /** GENERAL COLORS */
  --PRIMARY-BGCOLOR: #000;
  --SECONDARY-BGCOLOR: #1e1e1e;
  --TERTIARY-BGCOLOR: #3a3d41;

  --PRIMARY-COLOR: whitesmoke;
  --SECONDARY-COLOR: #bcbbbb;
  --DETAIL-COLOR: #707070;
  --TERTIARY-COLOR: #1e1e1e;

  /** ERROR COLORS */
  --ERROR-BGCOLOR: rgb(78, 53, 52);
  --ERROR-PRIMARY-COLOR: rgb(255, 218, 214);
  --ERROR-SECONDARY-COLOR: rgb(255, 84, 73);
  --ERROR-BORDER: 1px solid var(--ERROR-SECONDARY-COLOR);

  /** WARNING COLORS */
  --WARNING-BGCOLOR: rgb(80, 60, 32);
  --WARNING-PRIMARY-COLOR: rgb(250, 219, 162);
  --WARNING-SECONDARY-COLOR: orange;
  --WARNING-BORDER: 1px solid var(--WARNING-SECONDARY-COLOR);

  /** SUCCESS COLORS */
  --SUCCESS-BGCOLOR: rgb(53, 78, 52);
  --SUCCESS-PRIMARY-COLOR: rgb(218, 255, 214);
  --SUCCESS-SECONDARY-COLOR: rgb(84, 255, 73);
  --SUCCESS-BORDER: 1px solid var(--SUCCESS-SECONDARY-COLOR);

  /* --BETA-NOTIFICATION-COLOR: #fcdd78; */
  --BETA-NOTIFICATION-COLOR: #d3b392;
  --TOP-BANNER-BGCOLOR: #333;
  --TOP-BANNER-BORDER-COLOR: #707070;

  --BORDER-COLOR: #707070;

  --FOCUS-OUTLINE-COLOR: #4d78cc;

  --MAIN-NAV-BGCOLOR: #3a3d41;
  --MAIN-NAV-BORDER-COLOR: #707070;
  --MAIN-NAV-HOVER-BGCOLOR: #707070;
  --MAIN-NAV-HOVER-PRIMARY-COLOR: #1e1e1e;
  --MAIN-NAV-ACTIVE-BGCOLOR: #707070;
  --MAIN-NAV-INACTIVE-COLOR: #bcbbbb;
  --MAIN-NAV-ACTIVE-COLOR: whitesmoke;
  --MAIN-NAV-SHADOW-COLOR: #000;
  --SUBSCRIBE-BUTTON-BGCOLOR: #B267E6;

  --FOOTER-BORDER-COLOR: #707070;

  --FORM-BGCOLOR: #2b2b2b;
  --FORM-INPUT-BGCOLOR: #1e1e1e;
  --FORM-INPUT-COLOR: whitesmoke;
  --FORM-BORDER-COLOR: #000;

  --SETTINGS-LIST-BGCOLOR: #2b2b2b;
  --SETTINGS-LIST-COLOR: #bcbbbb;
  --SETTINGS-LIST-BORDER-COLOR: #000;

  --LINK-COLOR: #4d78cc;

  --INPUT-BGCOLOR: #a5a5a5;
  --INPUT-REGISTER-BGCOLOR: #109868;
  --SUBMIT-HOVER-BGCOLOR: #707070;

  /** SSV COLORS */
  --SENTENCE-TARGET-BGCOLOR: #121212;
  --TOOLBAR-BGCOLOR: #2b2b2b;
  --HELP-SECTION-BGCOLOR: #2b2b2b;
  --SELECTED-WORD-BGCOLOR: #2b2b2b;
  --TARGET-WORD-HOVER-COLOR: #2b2b2b;
  --SELECTED-WORD-OUTLINE-COLOR: #a5a5a5;
  --SELECTED-FRAGMENT-OUTLINE-COLOR: #D16969;
  --TOOLBAR-BTN-HOVER-BGCOLOR: #707070;
  --SENTENCE-BTN-COLOR: #bcbbbb;
  --SENTENCE-DISABLED-BTN-COLOR: #1e1e1e;

  --NEW-STEM-COLOR: #109868;
  --SEEN-STEM-COLOR: #4d78cc;
  --KNOWN-STEM-COLOR: var(--SECONDARY-COLOR);

  --NEW-END1-COLOR: #d0b428;
  --NEW-END2-COLOR: #d09028;
  --NEW-END3-COLOR: #d06028;

  --SEEN-END1-COLOR: #B267E6;
  --SEEN-END2-COLOR: #e85aaa;
  --SEEN-END3-COLOR: #e84545;

  --DIFF-CONTEXT-COLOR: #00cdcd;

  --KNOWN-END-COLOR: var(--SECONDARY-COLOR);
  --KNOWN-END1-COLOR: var(--SECONDARY-COLOR);
  --KNOWN-END2-COLOR: var(--SECONDARY-COLOR);
  --KNOWN-END3-COLOR: var(--SECONDARY-COLOR);

  /** FONTS */
  --FF: Helvetica, Arial, sans-serif;
  --TARGET-FF: Georgia, 'Times New Roman', Times, serif;

  /** MARGINS & PADDINGS */
  --GUTTER: 0.555rem;
  --SENTENCE-GUTTER: 0.555rem;
  --CONTENT-MAX-WIDTH: 100rem;
  --TOP-BANNER-PADDING: 0.444rem;
  --MAIN-NAV-PADDING: 0.444rem;
  --MAIN-NAV-LINK-PADDING: 0.4rem;
  --MAIN-NAV-LINK-BORDER-RADIUS: 0.222rem;
  --CONTENT-TOP-BOTTOM-MARGIN: 2rem;
  --CONTENT-LEFT-RIGHT-MARGIN: 1rem;
  --HEADER-TOP-BOTTOM-PADDING: 0.450rem;
  --CONTENT-TOP-BOTTOM-PADDING: 2rem;
  --CONTENT-LEFT-RIGHT-PADDING: 1rem;
  --SENTENCE-TOP-PADDING: 0.555rem;
  --SENTENCE-CONTENT-BOTTOM-PADDING: 3.8125rem;
  --SENTENCE-CONTENT-MARGIN: 0.375rem;

  /** BORDERS */
  --TOP-BANNER-BORDER: solid 1px var(--TOP-BANNER-BORDER-COLOR);
  --MAIN-NAV-BORDER: solid 1px var(--MAIN-NAV-BORDER-COLOR);
  --MAIN-NAV-DRAWER-BORDER: solid 1px var(--MAIN-NAV-BORDER-COLOR);
  --FOOTER-BORDER: solid 1px var(--FOOTER-BORDER-COLOR);
  --FORM-BORDER: solid 1px var(--FORM-BORDER-COLOR);
  --FORM-INVALID-BORDER: solid 1px var(--ERROR-PRIMARY-COLOR);

  /** SHADOWS */
  --MAIN-NAV-SHADOW: 0px 0px 6px var(--MAIN-NAV-SHADOW-COLOR);

  /** Z-INDICES */
  --GUIDE-CURRENT-TASK-Z-INDEX: 1004;
  --GUIDE-DROPDOWN-Z-INDEX: 1003;
  --GUIDE-CLICKBLOCKER-Z-INDEX: 1002;
  /* --GUIDE-CLICKBLOCKER-Z-INDEX: 1003;
  --GUIDE-DROPDOWN-Z-INDEX: 1002; */
  --GUIDE-FOCUS-Z-INDEX: 1001;
  --GUIDE-OVERLAY-Z-INDEX: 1000;
  --AUTH-CONTAINER-Z-INDEX: 999;
  --SSV-DRAWER-Z-INDEX: 600;
  --OVERLAY-Z-INDEX: 500;
  --TOP-BANNER-Z-INDEX: 400;
  --MAIN-NAV-Z-INDEX: 300;
  --MAIN-CONTENT-Z-INDEX: 200;
}

/** || MEDIA QUERIES - SIZES */
@media screen and (min-width: 1024px) {
  :root {
    --GUTTER: 1.5rem;
  }
}

@media screen and (min-width: 1440px) {
  :root {
    --GUTTER: 2rem;
  }
}

@media screen and (min-width: 2560px) {
  :root {
    --GUTTER: 4rem;
  }
}

/* @media (prefers-color-scheme: light) {
  :root {
    
  }
} */

/** || GOOGLE FONT API */
.great-vibes-regular {
  font-family: "Great Vibes", cursive;
  font-weight: 400;
  font-style: normal;
}

/** || GENERAL STYLES */
html {
  scroll-behavior: smooth;
}

body {
  width: 100vw;
  height: 100%;
  display: grid;
  place-content: center;
  font-family: var(--FF);
  background-color: var(--SECONDARY-BGCOLOR);
  color: var(--PRIMARY-COLOR);
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: var(--dynamic-height);
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.4625rem;
}

h4 {
  font-size: 1.25rem;
}

p {
  font-size: 1.25rem;
}

a {
  text-decoration: none;
  color: var(--LINK-COLOR);
}

/** || UTILITY CLASSES */
.page-wrapper {
  z-index: var(--MAIN-CONTENT-Z-INDEX);
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--SECONDARY-BGCOLOR);
}

.grid-center {
  width: 100%;
  flex-grow: 1;
  height: 100%;
  display: grid;
  place-content: center;
}

.grow {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.heroHeight {
  min-height: calc(100vh - 70.203px);
  /* min-height: calc(100vh - 109px); */
}

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
}

.relative {
  position: relative;
}

.wrap {
  display: inline-block;
  word-break: break-all;
  /* overflow-wrap: break-word; */
  white-space: wrap;
}

.nowrap {
  white-space: nowrap;
}

.divider {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--BORDER-COLOR);
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--BORDER-COLOR);
}

.divider::before {
  margin-right: .5em;
}

.divider::after {
  margin-left: .5em;
}

/* || FORMS */
.form {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
  max-width: 800px;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  border: var(--FORM-BORDER);
}

.form__admin {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  border: var(--FORM-BORDER);
  width: 100%;
}

.profileForm {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  max-width: 800px;
  background-color: var(--FORM-BGCOLOR);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  border: var(--FORM-BORDER);
}

.form__input:focus,
.form__textarea:focus,
.form__label:focus,
.form__submitButton:focus,
.form__checkbox:focus {
  outline: 1px solid var(--FOCUS-OUTLINE-COLOR);
  outline-offset: 1px;
}

.form__row {
  display: flex;
  flex-flow: row nowrap;
  gap: 1em;
  align-items: center;
  width: 100%;
}

.form__col {
  display: flex;
  flex-direction: column;
  gap: 0.222rem;
  justify-content: center;
  align-items: flex-start;
}

.form__label {
  font-weight: lighter;
  font-size: 1.25rem;
}

.form__requirements {
  font-size: 1rem;
  font-style: italic;
  font-weight: lighter;
  color: var(--SECONDARY-COLOR);
  opacity: 0.40;
}

.form__input {
  font-size: 1.25rem;
  color: var(--FORM-INPUT-COLOR);
  padding: 0.3125rem;
  border-radius: 0.222rem;
  width: 100%;
  background-color: var(--FORM-INPUT-BGCOLOR);
  border: var(--FORM-BORDER);
}

.form__textarea {
  color: var(--FORM-INPUT-COLOR);
  font-weight: bold;
  padding: 0.25rem;
  border-radius: 0.222rem;
  width: 100%;
  background-color: var(--FORM-INPUT-BGCOLOR);
  border: var(--FORM-BORDER);
}

.form__input--valid {
  border: var(--SUCCESS-BORDER);
  background-color: var(--SUCCESS-BGCOLOR);
  color: var(--SUCCESS-PRIMARY-COLOR);
}

.form__input--invalid {
  border: var(--ERROR-BORDER);
  background-color: var(--ERROR-BGCOLOR);
  color: var(--ERROR-PRIMARY-COLOR);
}

.form__submitButton {
  border: var(--SUCCESS-BORDER);
  color: var(--SUCCESS-PRIMARY-COLOR);
  font-weight: bold;
  padding: 0.4rem;
  font-size: 1.25rem;
  border-radius: 0.222rem;
  width: 100%;
  background-color: var(--SUCCESS-BGCOLOR);
  cursor: pointer;
}

.form__submitButton--warning {
  border: var(--WARNING-BORDER);
  color: var(--WARNING-PRIMARY-COLOR);
  font-weight: bold;
  padding: 0.4rem;
  font-size: 1.25rem;
  border-radius: 0.222rem;
  width: 100%;
  background-color: var(--WARNING-BGCOLOR);
  cursor: pointer;
}

.form__submitButton--disabled {
  border: none;
  opacity: 34%;
  cursor: not-allowed !important;
  color: var(--SECONDARY-BGCOLOR);
  font-weight: bold;
  border-radius: 0.222rem;
  border: 1px solid var(--SECONDARY-BGCOLOR);
  padding: 0.4rem;
  font-size: 1.25rem;
  width: 100%;
}

.form__showPassword {
  color: var(--PRIMARY-COLOR);
  position: absolute;
  right: 5px;
  font-size: 1.25rem;
  font-weight: regular;
  width: 25px;
  height: 20px;
  cursor: pointer;
}

.form__successMsg {
  color: var(--VALID-PRIMARY-COLOR);
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
}

.form__errorMsg {
  color: var(--ERROR-PRIMARY-COLOR);
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

/** || FRAGMENT STYLES */
.stem.new {
  color: var(--NEW-STEM-COLOR);
}

.stem.seen {
  color: var(--SEEN-STEM-COLOR);
}

.stem.known {
  color: var(--KNOWN-STEM-COLOR);
}

.ending1.new {
  color: var(--NEW-END1-COLOR);
}

.ending1.seen {
  color: var(--SEEN-END1-COLOR);
}

.ending1.known {
  color: var(--KNOWN-END1-COLOR);
}

.ending2.new {
  color: var(--NEW-END2-COLOR);
}

.ending2.seen {
  color: var(--SEEN-END2-COLOR);
}

.ending2.known {
  color: var(--KNOWN-END2-COLOR);
}

.ending3.new {
  color: var(--NEW-END3-COLOR);
}

.ending3.seen {
  color: var(--SEEN-END3-COLOR);
}

.ending3.known {
  color: var(--KNOWN-END3-COLOR);
}

.known {
  color: var(--PRIMARY-COLOR);
}

/** || INFO STYLES */
.attributeValue.new {
  color: var(--SECONDARY-COLOR)
}

.attributeValue.seen {
  color: var(--SECONDARY-COLOR)
}

.attributeValue.known {
  color: var(--SELECTED-FRAGMENT-OUTLINE-COLOR)
}

.attributeValue.undefined {
  color: var(--SELECTED-FRAGMENT-OUTLINE-COLOR)
}

/** || ANIMATIONS */
@keyframes flash {
  0% {
    opacity: initial;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: initial;
  }
}

.flash-animation {
  animation: flash 1.5s infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn-animation {
  animation: fadeIn 1s 1s ease-in forwards;
}