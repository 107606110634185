.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--FORM-BGCOLOR);
  background: linear-gradient(to bottom, var(--SENTENCE-TARGET-BGCOLOR), #16223b);
  padding: 1rem 1rem;
  border-radius: 0.222rem;
  border: var(--FORM-BORDER);
  margin-bottom: 1rem;
}

.productTopRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.companyName {
  font-family: var(--TARGET-FF);
  font-weight: 900;
  font-size: 1.65rem;
  white-space: nowrap;
}

.price {
  font-weight: 900;
  text-align: end;
  /* color: #109868; */
  font-size: 1.4625rem;
  margin-left: 1.5rem;
}

.description {
  font-weight: 900;
  width: 100%;
  font-size: 1.444rem;
  margin-bottom: 1rem;
}

.cancel {
  font-style: italic;
  color: whiteSMOKE;
  width: 100%;
  font-weight: lighter;
  font-size: 1rem;
}

.subscribeAccentColor {
  color: white;
  font-weight: bold;
}

.bottomRow {
  width: 100%;
  display: flex;
  flex-flow: row-wrap;
  align-items: flex-end;
  justify-content: space-between;
}

/** || SUBSCRIBE BUTTON STYLES */
.subscribeButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  padding: 0.4rem;
  border-radius: 0.222rem;
  background-color: var(--SUBSCRIBE-BUTTON-BGCOLOR);
  color: var(--TERTIARY-COLOR);
  cursor: pointer;

  border: none;
}

.subscribeIcon {
  font-size: 1.5rem;
}

.subscribeText {
  font-family: var(--TARGET-FF);
  font-size: 1.25rem;
  font-weight: bolder;
}

.stripeContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 0.5rem;
}

.stripeText {
  font-size: 1rem;
  font-style: italic;
  text-align: end;
}

.stripeIconContainer {
  position: relative;
  width: 60px;
  height: 48px;
}

.stripeIcon {
  position: absolute;
  bottom: -14px;
  font-size: 3rem;
  text-align: end;
}