.logo {
  grid-area: header0;

  /* display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start; */

  /** logo width */
  display: inline;
  width: fit-content;

  padding-bottom: 2px;
  cursor: pointer;
}

.logo:hover {}

.locationContainer {
  grid-area: header1;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 2px;
}

.locationText {
  font: 0.75rem var(--TARGET-FF);
  text-align: center;

}

.bookTitle {
  font-style: italic;
  font-size: 1.125rem;
}

.chapterTitle {
  opacity: 0.888;
  font-size: 0.8775rem;
}

.sentencePosition {
  font-size: 0.75rem;
}

.header2__columnContainer {
  grid-area: header2;
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 2px;
}

.header2__iconRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
}

.knownCountContainer {
  display: flex;
  flex-direction: row;
  gap: 0.400rem;
  align-items: center;
  justify-content: flex-end;
  bottom: 0px;
  right: 0px;
  text-align: right;
}

.flag {
  display: block;
  height: auto;
  width: 1rem;
}

.knownCount {
  font-family: var(--TARGET-FF);
  font-weight: 550;
  font-size: 1.2rem;
}

.headerExit {
  color: var(--SECONDARY-COLOR);
  text-align: center;
}

.iconRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.icon {
  font-size: 1rem;
  padding: 1px;
  /* border-radius: 0.222rem; */
  color: var(--SENTENCE-BTN-COLOR);
  cursor: pointer;
}

.icon__disabled {
  font-size: 1rem;
  padding: 1px;
  border-radius: 0.222rem;
  color: var(--SENTENCE-DISABLED-BTN-COLOR);
  cursor: default;
}

@media (pointer: fine) {
  .icon:hover {
    color: var(--PRIMARY-COLOR);
  }
}

.icon__admin {
  font-size: 1rem;
  background-color: orange;
  color: #000;
  padding: 4px;
  border-radius: 0.222rem;
  cursor: pointer;
}