.section {
  max-width: var(--CONTENT-MAX-WIDTH);
  padding: var(--CONTENT-TOP-BOTTOM-PADDING) 0.555rem;
  border-radius: 0.222rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  color: var(--SECONDARY-COLOR);
}

.h2_error {
  margin-bottom: 1rem;
  color: var(--ERROR-PRIMARY-COLOR);
}

.h2_success {
  margin-bottom: 1rem;
  color: var(--SUCCESS-PRIMARY-COLOR);
}

.link_success {
  text-decoration: underline;
  color: var(--SUCCESS-SECONDARY-COLOR);
  font-weight: bold;
}

.resend {
  color: var(--ERROR-PRIMARY-COLOR);
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.return {
  text-align: left;
  font-size: 1.125rem;
  font-weight: lighter;
  margin: 1rem 0;
}