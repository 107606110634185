.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem var(--GUTTER) 0 var(--GUTTER);
}

.headerLink,
.headerSpacer {
  width: 32px;
}