.container {
  /* height: 100vh; */
  height: 100%;
  flex-grow: 1;
  width: 100%;
  /* max-width: 1440px; */
  background-color: var(--BGCOLOR);
  overflow-x: hidden;
  position: relative;
}

.grid {
  height: 100%;
  /* height: 100%; */
  flex-grow: 1;
  padding: var(--SENTENCE-TOP-PADDING) var(--SENTENCE-GUTTER) 0 var(--SENTENCE-GUTTER);
  display: grid;
  grid-template-rows: auto auto 1fr 1fr 1fr 1fr 0 1fr 1fr;
  grid-template-columns: 1fr auto 1fr;
  /* min-height: 100vh;
  max-height: 100vh; */

  height: var(--dynamic-height);
  overflow: hidden;
  grid-template-areas:
    "header0 header1 header2"
    "cefr cefr cefr"
    "target target target"
    "target target target"
    "target target target"
    "target target target"
    "toolbar toolbar toolbar"
    "info info info"
    "info info info";
}

.overlay {
  /* height: 100vh; */
  height: 100%;
  flex-grow: 1;
  position: absolute;
  z-index: var(--OVERLAY-Z-INDEX);
  top: 0;
  bottom: 0;
  pointer-events: none;
  width: 100%;
  /* max-width: 1440px; */
  display: grid;
  place-content: end center;
}

.errLink {
  font-size: 1.25rem;
  text-decoration: none;
}