.container {
  min-height: var(--HERO-HEIGHT);
  max-width: var(--CONTENT-MAX-WIDTH);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  padding: var(--CONTENT-TOP-BOTTOM-PADDING) 0.555rem;
  background-color: var(--SECONDARY-BGCOLOR);
  padding-left: var(--GUTTER);
  padding-right: var(--GUTTER);
}

.topBanner {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  margin-bottom: 1rem;
}

.usernameContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.333rem;
}

.subAccent {
  color: var(--PRIMARY-COLOR);
}

.header {
  font-weight: 900;
  align-items: center;
  color: var(--SECONDARY-COLOR);
}

.memberhipDate {
  font-size: 0.97125rem;
  color: var(--BORDER-COLOR);
  font-style: italic;
}

.logout {
  background-color: var(--FORM-BGCOLOR);
  padding: 0.400rem;
  border-radius: 0.222rem;
  border: var(--FORM-BORDER);
  color: inherit;
  font-size: 1.25rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  color: var(--SECONDARY-COLOR);
  cursor: pointer;
  height: fit-content;
  margin-left: 2.5rem;
}

@media (pointer: fine) {
  .logout:hover {
    color: var(--PRIMARY-COLOR);
  }
}

.adminLink {
  width: fit-content;
}