.gridTarget {
  grid-area: target;
  width: 100%;
}

.sentenceContainer {
  width: 100%;
  flex-grow: 1;
  display: grid;
  place-content: center;
}

.sentence {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: flex-start;
  gap: 1rem;
  font-family: var(--TARGET-FF);
  list-style-type: none;
  margin: 0px 4px 0px 4px;
  padding: 0;
}

.paragraphIndentation {
  margin-left: 32px;
}

.translation {
  text-align: center;
  font-size: 1.14285714rem;
  opacity: 0.95;
  color: lightcyan;
  transition: opacity 0.3s ease, color 0.3s ease;
}

.punctuation {
  background-color: var(--SENTENCE-TARGET-BGCOLOR);
  transition: background-color 0.3s ease;
}

.wordInstance {
  font-size: 2rem;
  font-family: var(--TARGET-FF);
  cursor: default;
  transition: font-size 0.3s ease;
}

@media screen and (min-width: 414px) {
  .translation {
    font-size: 1.42857143rem;
  }

  .wordInstance {
    font-size: 2rem;
  }

  .gridTarget {
    padding-bottom: 3rem;
  }
}

@media screen and (min-width: 768px) {
  .translation {
    font-size: 2.28571429rem;
  }

  .wordInstance {
    font-size: 3rem;
  }

  .gridTarget {
    padding-bottom: 3rem;
  }
}

@media screen and (min-width: 1440px) {
  .translation {
    font-size: 2.85714286rem;
  }

  .wordInstance {
    font-size: 4rem;
  }

  .gridTarget {
    padding-bottom: 3rem;
  }
}

.wordInstanceText {
  cursor: pointer;
  text-align: center;
  transition: color 0.3s ease;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.languageRow {
  margin: 0;
  padding: 0;
  line-height: 0.3;
}

.wordInstanceLanguage {
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 0.555rem;
  font-style: italic;
  color: var(--BORDER-COLOR);
}

.fragment {
  border-radius: 0.09rem;
  transition: outline 0.3s ease, background-color 0.3s ease;
}

@media (pointer: fine) {
  .fragment:hover {
    outline: 2px solid #a5a5a5;
    border-radius: 0.090rem;
  }

  .fragment.selectedFragment:hover {
    outline: 2px solid var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  }
}

.selectedFragment {
  outline: 2px solid var(--SELECTED-FRAGMENT-OUTLINE-COLOR);
  transition: outline 0.3s ease;
}

.invisible {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.selectedWord {
  /* background-color: var(--SELECTED-WORD-BGCOLOR); */
  transition: background-color 0.3s ease;
}