.container {
  height: 100%;
  /* flex-grow: 1; */
  position: absolute;
  z-index: var(--GUIDE-OVERLAY-Z-INDEX);
  top: 0;
  bottom: 0;
  width: 100%;
  display: grid;
  place-content: center;
  background-color: hsla(0, 0%, 0%, 0.250);
  backdrop-filter: blur(5px);
  padding: 2rem;
}

.overlay {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
  width: 100%;
  border-radius: 0.222rem;
  background-color: var(--SECONDARY-BGCOLOR);
  padding: 2rem;
  border: 1px solid #000;
  color: var(--PRIMARY-COLOR);
}

.h2 {
  margin-bottom: 0.500rem;
  text-align: center;
}

.begin {
  /* border: none; */
  border: var(--SUCCESS-BORDER);
  /* color: var(--SECONDARY-BGCOLOR); */
  color: var(--SUCCESS-PRIMARY-COLOR);
  font-weight: bold;
  /* padding: 0.25rem; */
  padding: 0.4rem;
  border-radius: 0.222rem;
  width: 100%;
  background-color: var(--SUCCESS-BGCOLOR);
  font-size: 1rem;
  cursor: pointer;
  /* margin-bottom: 2rem; */
}

.skip {
  color: var(--SECONDARY-COLOR);
  opacity: 0.50;
  /* font-size: 0.800rem; */
  font-size: 1rem;
  font-style: italic;
  /* text-align: right; */
  text-align: center;
  cursor: pointer;
}

@media (pointer: fine) {
  .skip:hover {
    text-decoration: underline;
  }
}