.digit {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 1rem;
    line-height: .5rem;
    /* width: 0.75rem; */
}

.digitWrapper {
    position: relative;
    /* width: 100%; */
    height: 100%;
}

.digitElement {
    position: absolute;
    inset: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    backface-visibility: hidden;
}

.delayedDigit {
    z-index: 2;
}

.prevDigit {
    z-index: 1;
}

@keyframes flipUpEnter {
    from {
        transform: translateY(100%);
        /* opacity: 0; */
    }

    to {
        transform: translateY(0);
        /* opacity: 1; */
    }
}

@keyframes flipUpExit {
    from {
        transform: translateY(0);
        /* opacity: 1; */
    }

    to {
        transform: translateY(-100%);
        /* opacity: 0; */
    }
}

@keyframes flipDownEnter {
    from {
        transform: translateY(-100%);
        /* opacity: 0; */
    }

    to {
        transform: translateY(0);
        /* opacity: 1; */
    }
}

@keyframes flipDownExit {
    from {
        transform: translateY(0);
        /* opacity: 1; */
    }

    to {
        transform: translateY(100%);
        /* opacity: 0; */
    }
}

.flipUpEnter {
    animation: flipUpEnter 500ms ease-out;
}

.flipUpExit {
    animation: flipUpExit 500ms ease-out;
}

.flipUpEnterDelayed {
    animation: flipUpEnter 500ms ease-out 500ms;
}

.flipUpExitDelayed {
    animation: flipUpExit 500ms ease-out 500ms;
}

.flipDownEnter {
    animation: flipDownEnter 500ms ease-out;
}

.flipDownExit {
    animation: flipDownExit 500ms ease-out;
}

.flipDownEnterDelayed {
    animation: flipDownEnter 500ms ease-out 500ms;
}

.flipDownExitDelayed {
    animation: flipDownExit 500ms ease-out 500ms;
}