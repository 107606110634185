.gridInfo_content {
  min-height: var(--HERO-HEIGHT);
  grid-area: info_content;
  display: flex;
  flex-direction: column;
  max-width: var(--CONTENT-MAX-WIDTH);
  width: 100%;
  overflow-y: auto;
  padding: var(--CONTENT-TOP-BOTTOM-PADDING) 0.555rem;
  /* margin-bottom: 3.71875rem; */
  /* margin-top: var(--SENTENCE-CONTENT-MARGIN); */
  /* scroll-padding-bottom: 3.71875rem; */
}

.gridInfo {
  display: grid;
  grid-template-areas:
    "info_header info_header info_header"
    "info_content info_content info_content";
  grid-template-rows: auto 1fr;
  grid-area: info;
  margin-top: var(--SENTENCE-CONTENT-MARGIN);
  border-top: 1px solid var(--BORDER-COLOR);
  background-color: #333;
  width: 100%;
  max-width: 100%;
  /* Adjust the width as needed */
}

.translationCol {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 10px;
  width: 100%;
}

.header {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  font-weight: 900;
  color: #fff;
  font-size: 1.5rem;
}

.translationContent {
  flex-grow: 1;
  width: 100%;
  min-height: 0;
  gap: 0.5rem;
  overflow-y: auto;
}