.section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  padding: 0.5rem;
  border-radius: 0.222rem;
  border: var(--SUCCESS-BORDER);
  background-color: var(--SUCCESS-BGCOLOR);
  color: var(--SUCCESS-PRIMARY-COLOR);
}

.flexCol {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.checkmark {
  color: var(--SUCCESS-SECONDARY-COLOR);
}