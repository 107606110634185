.container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: lighter;
}

.pass {
  color: var(--SUCCESS-SECONDARY-COLOR);
}

.fail {
  color: var(--ERROR-SECONDARY-COLOR);
}